import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Sales Chart",
    },
  },
};
const Chart = (props) => {
  const [visualizedData, setVisualizedData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    const labels = props.data.map((a) => {
      const [month, day, year] = a.day.split("/");
      const date = new Date(2000, month - 1);
      const monthName = date.toLocaleString("en-US", { month: "long" });
      return `${day} ${monthName} ${year}`;
    });
    const datasets = [
      {
        label: "Gross Sales",
        data: props.data.map((a) => a.grossSales),
        borderColor: "#1303fc",
        backgroundColor: "#1303fc",
      },
      {
        label: "Net Sales",
        data: props.data.map((a) => a.netSales),
        borderColor: "#db0db2",
        backgroundColor: "#db0db2",
      },
      {
        label: "Taxes",
        data: props.data.map((a) => a.tax),
        borderColor: "#e80505",
        backgroundColor: "#e80505",
      },
      {
        label: "Returns",
        data: props.data.map((a) => a.returns),
        borderColor: "#05c5f5",
        backgroundColor: "#05c5f5",
      },
      {
        label: "Coupons",
        data: props.data.map((a) => a.coupons),
        borderColor: "#1fe805",
        backgroundColor: "#1fe805",
      },
      {
        label: "Total items Sold",
        data: props.data.map((a) => a.totalItemsSold),
        borderColor: "#deaa0d",
        backgroundColor: "#deaa0d",
      },
    ];
    setVisualizedData({
      labels,
      datasets,
    });
  }, [props.data]);
  return <Line options={options} data={visualizedData} />;
};

export default Chart;
