import Select from "../UI/Select";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import SearchInput from "../UI/SearchInput";

import styles from "./Users.module.css";
import User from "./User";
import LoadingSpinner from "../Spinner/LoadingSpinner";
const Users = (props) => {
  // const params = useParams();
  // const { collection, category, type } = params;

  const [users, setUsers] = useState([]);
  const [type, setType] = useState("Pending");
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectOptions = [
    { id: 1, value: "activated" },
    { id: 2, value: "not-activated" },
    { id: 3, value: "Declined" },
    { id: 4, value: "Created" },
  ];
  useEffect(() => {
    fetchUsers();
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchId === "") fetchUsers();
    else {
      const filteredUsers = users.filter((user) => {
        return (
          user.userId.toLowerCase().includes(searchId.toLowerCase()) ||
          user.displayName.toLowerCase().includes(searchId.toLowerCase()) ||
          user.firstName.toLowerCase().includes(searchId.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchId.toLowerCase()) ||
          user.country.toLowerCase().includes(searchId.toLowerCase()) ||
          user.discord.toLowerCase().includes(searchId.toLowerCase()) ||
          user.battlenet.toLowerCase().includes(searchId.toLowerCase()) ||
          user.email.toLowerCase().includes(searchId.toLowerCase())
        );
      });
      setUsers(filteredUsers);
    }
  }, [searchId]);

  const fetchUsers = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    setIsLoading(false);
    setUsers(data);
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };
  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };

  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>Users</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={selectOptions}
          />
        </Col>
        <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search users by id"}
          />
        </Col>
      </Row>
      <Row>
        <label>Total: {users.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>ID</td>
                <td className={styles.tableCell}>Username</td>
                <td className={styles.tableCell}>First Name</td>
                <td className={styles.tableCell}>Last Name</td>
                <td className={styles.tableCell}>Country</td>
                <td className={styles.tableCell}>Email</td>
                <td className={styles.tableCell}>Discord</td>
                <td className={styles.tableCell}>Game Tag</td>
                <td className={styles.tableCell}>Role</td>

                <td className={styles.tableCell}>Activated</td>
                <td className={styles.tableCell}>Date</td>
              </tr>
              {users.map((user) => {
                return <User user={user} key={user.userId} />;
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Users;
