import settings from "../../../settings.json";
import { Col, Row } from "react-bootstrap";
import SearchInput from "../../UI/SearchInput";
import Button from "../../UI/Button";
import React, { useState } from "react";
import Label from "../../UI/Label";
import ProductImage from "../ProductImage";
const EditImage = (props) => {
  const product = props.product;
  const [frontImage, setFrontImage] = useState(product.image[0]);
  const [backImage, setBackImage] = useState(product.image[1]);

  const [previewImage, setPreviewImage] = useState(product.image);
  const [isRuneword, setIsRuneword] = useState(product.image.length > 1);

  const frontImageHandler = (e) => {
    setFrontImage(e.target.value);
  };
  const backImageHandler = (e) => {
    setBackImage(e.target.value);
  };

  const previewHandler = () => {
    if (backImage !== undefined && backImage.length > 5) {
      setPreviewImage([frontImage, backImage]);
      setIsRuneword(true);
    } else {
      setPreviewImage([frontImage]);
      setIsRuneword(false);
    }
  };
  const applyChanges = async () => {
    props.update("image", previewImage);
  };
  return (
    <React.Fragment>
      <Row>
        <Col xs={6}>
          <Row>
            <Col>
              <Button text={"Show Preview"} onClick={previewHandler} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Front image</Label>
              <SearchInput
                type="text"
                value={frontImage}
                onChangeHandler={frontImageHandler}
                placeholder={frontImage}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Base image</Label>
              <SearchInput
                type="text"
                value={backImage}
                onChangeHandler={backImageHandler}
                placeholder={backImage}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col>
              <Button text={"Save Image"} onClick={applyChanges} />
            </Col>
          </Row>
          <Row>
            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
              <ProductImage isRuneword={isRuneword} image={previewImage} />
            </div>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EditImage;
