import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import Select from "../UI/Select";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useState } from "react";
import Label from "../UI/Label";
import TextArea from "../UI/TextArea";
import Title from "../UI/Title";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Checkbox from "../UI/Checkbox";
import Collections from "./Collections";

const AddNewCoupon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("percentage off");
  const [percantageOff, setPercentageOff] = useState(0);
  const [amountOffUSD, setAmountOffUSD] = useState(0);
  const [amountOffEUR, setAmountOffEUR] = useState(0);
  const [startDate, setStartDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [neverEnds, setNeverEnds] = useState(false);
  const [includeAllCollections, setIncludeAllCollections] = useState(true);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [minimumSpendUSD, setMinimumSpendUSD] = useState("");
  const [minimumSpendEUR, setMinimumSpendEUR] = useState("");
  const [maxTimesUsed, setMaxTimesUsed] = useState(0);
  const [isLimited, setIsLimited] = useState(false);

  const navigate = useNavigate();
  
  const codeHandler = (e) => {
    setCode(e.target.value);
  };
  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };
  const amountHandler = (e) => {
    setAmountOffUSD(e.target.value);
    const eur = (+e.target.value * 0.93).toFixed(2);
    console.log(eur);
    setAmountOffEUR(eur);
  };
  const percentageHandler = (e) => {
    setPercentageOff(e.target.value);
  };
  const neverEndsHandler = (e) => {
    setNeverEnds(e.target.checked);
    console.log(neverEnds);
  };

  const startDateHandler = (e) => {
    setStartDate(e.target.value);
  };
  const expireHandler = (e) => {
    setExpiryDate(e.target.value);
  };

  const minimumHandler = (e) => {
    setMinimumSpendUSD(e.target.value);
    const eur = (+e.target.value * 0.93).toFixed(2);
    setMinimumSpendEUR(eur);
  };

  const allCollectionsHandler = (e) => {
    setIncludeAllCollections(e.target.checked);
  };
  const generateCodeHandler = () => {
    let randomCode = "GGIC-";
    for (let i = 0; i < 5; i++) {
      randomCode += ~~(Math.random() * 10);
    }
    setCode(randomCode);
  };

  const collectionHandler = (name, action) => {
    if (action) {
      setSelectedCollections([...selectedCollections, name]);
    } else {
      const index = selectedCollections.findIndex((el) => el === name);
      selectedCollections.splice(index, 1);
      setSelectedCollections([...selectedCollections]);
    }
  };

  const maxTimesHandler = (e) => {
    setMaxTimesUsed(e.target.value);
  };

  const limitHandler = (e) => {
    setIsLimited(e.target.checked);
    if (e.target.checked) setMaxTimesUsed(0);
  };

  const createCouponHandler = async () => {
    const coupon = {
      code: code,
      description: description,
      type: type,
      percantageOff: +percantageOff,
      amountOffUSD: +amountOffUSD,
      amouuntOffEUR: +amountOffEUR,
      startDate: startDate,
      expiryDate: expiryDate,
      neverEnds: neverEnds,
      minimumSpendUSD: +minimumSpendUSD,
      minimumSpendEUR: +minimumSpendEUR,
      includeAllCollections: includeAllCollections,
      selectedCollections: selectedCollections,
      isLimited: isLimited,
      maxTimesUsed: +maxTimesUsed,
    };
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    /*const response =*/ await fetch(
      `${serverAddress}/api/admin/coupons/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: coupon,
        }),
      }
    );
    // const data = await response.text();
    setIsLoading(false);
    navigate("/coupons");
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <Title>Create New Coupon</Title>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Code:</Label>
          <SearchInput
            type="text"
            value={code}
            onChangeHandler={codeHandler}
            placeholder={"discourd code"}
          />
        </Col>
        <Col xs={2}>
          <Label>Random Code</Label>
          <Button onClick={generateCodeHandler} text={"Generate"}></Button>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Label>Description:</Label>
          <TextArea
            value={description}
            onChangeHandler={descriptionHandler}
            placeholder={"coupon description"}
          />
        </Col>
      </Row>
      <Title>General</Title>
      <Row>
        <Col xs={8}>
          <Label>Counpon type</Label>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={[
              { id: 1, value: "percentage off" },
              { id: 2, value: "amount off" },
            ]}
          />
        </Col>
      </Row>
      {type === "percentage off" ? (
        <Row>
          <Col xs={8}>
            <Label>Percentage off</Label>
            <SearchInput
              type="number"
              value={percantageOff}
              onChangeHandler={percentageHandler}
              placeholder={"percentage off"}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={4}>
            <Label>Coupon Amount USD</Label>
            <SearchInput
              type="number"
              value={amountOffUSD}
              onChangeHandler={amountHandler}
              placeholder={type}
            />
          </Col>
          <Col xs={4}>
            <Label>Coupon Amount EUR</Label>
            <SearchInput
              type="number"
              value={amountOffEUR}
              onChangeHandler={amountHandler}
              placeholder={type}
              disabled={true}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={8}>
          <Label>Start Date</Label>
          <SearchInput
            type="date"
            value={startDate}
            onChangeHandler={startDateHandler}
            placeholder={"DD/MM/YYYY"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Expire Date</Label>
          <SearchInput
            type="date"
            value={expiryDate}
            onChangeHandler={expireHandler}
            placeholder={"DD/MM/YYYY"}
            disabled={neverEnds}
          />
        </Col>
        <Col xs={2}>
          <Label>Never Ends</Label>
          <Checkbox value={neverEnds} onChangeHandler={neverEndsHandler} />
        </Col>
      </Row>
      <Title>Coupon Restrictions</Title>
      <Row>
        <Col xs={4}>
          <Label>Minimum Spend USD</Label>
          <SearchInput
            type="number"
            value={minimumSpendUSD}
            onChangeHandler={minimumHandler}
            placeholder={"Minimum amount to spend"}
          />
        </Col>
        <Col xs={4}>
          <Label>Minimum Spend EUR</Label>
          <SearchInput
            type="number"
            value={minimumSpendEUR}
            onChangeHandler={minimumHandler}
            placeholder={"Minimum amount to spend"}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Selected Collections</Label>
          <Collections
            select={collectionHandler}
            includeAll={includeAllCollections}
          />
        </Col>
        <Col xs={2}>
          <Label>Include All Collections</Label>
          <Checkbox
            value={includeAllCollections}
            onChangeHandler={allCollectionsHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Max times used</Label>
          <SearchInput
            type="number"
            value={maxTimesUsed}
            onChangeHandler={maxTimesHandler}
            placeholder={"max times used"}
            disabled={isLimited}
          />
        </Col>
        <Col xs={2}>
          <Label>Not limited</Label>
          <Checkbox value={isLimited} onChangeHandler={limitHandler} />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Title></Title>
          <Button onClick={createCouponHandler} text={"Create Coupon"}></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewCoupon;
