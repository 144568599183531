import styles from "./User.module.css";
import { useNavigate } from "react-router-dom";
const User = (props) => {
  const user = props.user;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/users/${user.userId}`, { state: user });
  };
  return (
    <tr className={styles.tableRow} onClick={handleClick}>
      <td className={styles.tableCell}>{user.userId}</td>
      <td className={styles.tableCell}>{user.displayName}</td>
      <td className={styles.tableCell}>{user.firstName}</td>
      <td className={styles.tableCell}>{user.lastName}</td>
      <td className={styles.tableCell}>{user.country}</td>
      <td className={styles.tableCell}>{user.email}</td>
      <td className={styles.tableCell}>{user.discord}</td>
      <td className={styles.tableCell}>{user.battlenet}</td>
      <td className={styles.tableCell}>{user.role}</td>
      <td className={styles.tableCell}>
        {user.activated ? (
          <span className={styles.green}>Yes</span>
        ) : (
          <span className={styles.red}>No</span>
        )}
      </td>
      <td className={styles.tableCell}>{user.dateCreated}</td>
    </tr>
  );
};

export default User;
