import React from "react";
import styles from "./CustomConfirm.module.css";
import Button from "./Button";
import { Col, Row } from "react-bootstrap";

const CustomConfirm = (props) => {
  return (
    <div className={styles.container}>
      <h1>Are you sure to delete this item {props.title}?</h1>
      <Row>
        <Col>
          <Button onClick={props.onClose} text={"No"} />
        </Col>
        <Col>
          <Button onClick={props.deleteHandler} text={"Yes, Delete it"} />
        </Col>
      </Row>
    </div>
  );
};

export default CustomConfirm;
