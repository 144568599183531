import styles from "./Checkbox.module.css";
const Checkbox = (props) => {
  return (
    <input
      type="checkbox"
      checked={props.value}
      onChange={props.onChangeHandler}
      className={styles.checkbox}
    ></input>
  );
};

export default Checkbox;
