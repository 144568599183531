import styles from "./Products.module.css";
import Select from "../UI/Select";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import SearchInput from "../UI/SearchInput";
import Options from "./Options.json";
import Product from "./Product";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
const Products = () => {
  // add filtering options to remove from copy not all to keep the all products on site 24/7

  const sortedOptions = Options.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [database, setDatabase] = useState("ProductsD2RLadder");
  const [collection, setCollection] = useState("Amulets");
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [type, setType] = useState("all");
  const [typeOptions, setTypeOptions] = useState([]);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const databaseOptions = [
    { id: 1, value: "ProductsD2RLadder" },
    { id: 2, value: "ProductsD2RNLadder" },
    { id: 3, value: "ScrapperD2RLadder" },
    { id: 4, value: "ScrapperD2RNLadder" },
  ];

  useEffect(() => {
    const collOptions = sortedOptions.map((option) => {
      return { id: option.key, value: option.title };
    });
    setCollectionOptions(collOptions);
  }, [database]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchProducts();
    setTypeOptions([]);
  }, [collection]);

  useEffect(() => {
    if (searchId === "") setFilteredProducts(products);
    else {
      const filtered = products.filter((product) => {
        return product.title.toLowerCase().includes(searchId.toLowerCase());
      });
      setFilteredProducts(filtered);
    }
  }, [searchId]);

  useEffect(() => {
    if (category === "all") {
      setFilteredProducts(products);
      return;
    }
    const filtered = products.filter((product) => {
      return product.category.toLowerCase().includes(category);
    });

    const allTypes = filtered.map((a) => a.type);
    const possibleTypes = [...new Set(allTypes)];
    const formattedTypes = possibleTypes.map((cat, i) => {
      return { id: i + 1, value: cat };
    });
    const everyType = [{ id: 0, value: "all" }, ...formattedTypes];
    setTypeOptions(everyType);
    setType("all");
    setFilteredProducts(filtered);
  }, [category]);

  useEffect(() => {
    if (type === "all") {
      const matchingProducts = products.filter((product) => {
        return product.category.toLowerCase().includes(category);
      });
      setFilteredProducts(matchingProducts);
      setType("all");
      return;
    }
    console.log("1");
    const filtered = products.filter((product) => {
      if (product.type !== null)
        return product.type.toLowerCase().includes(type);
    });
    setFilteredProducts(filtered);
  }, [type]);

  const fetchProducts = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        database: database,
        collection: collection,
      }),
    });
    setIsLoading(false);
    const data = await response.json();

    const allCategories = data.map((a) => a.category);
    const possibleCategories = [...new Set(allCategories)];
    const formattedCategoires = possibleCategories.map((cat, i) => {
      return { id: i + 1, value: cat };
    });

    const everyCategory = [{ id: 0, value: "all" }, ...formattedCategoires];
    setCategoryOptions(everyCategory);

    const sortedByTitles = data.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    setProducts(sortedByTitles);
    setFilteredProducts(sortedByTitles);
  };

  const categoryHandler = (e) => {
    setCategory(e.target.value);
  };
  const databaseHandler = (e) => {
    setDatabase(e.target.value);
  };
  const collectionHandler = (e) => {
    setCollection(e.target.value);
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };

  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };

  const addHandler = () => {
    navigate("./add-new");
  };

  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>Products</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Select
            onChangeHandler={databaseHandler}
            value={database}
            options={databaseOptions}
          />
        </Col>
        <Col xs={2}>
          <Select
            onChangeHandler={collectionHandler}
            value={collection}
            options={collectionOptions}
          />
        </Col>
        <Col xs={2}>
          <Select
            onChangeHandler={categoryHandler}
            value={category}
            options={categoryOptions}
          />
        </Col>
        <Col xs={2}>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={typeOptions}
          />
        </Col>
        <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search products by title"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button text={"Add new product"} onClick={addHandler}></Button>
        </Col>
      </Row>
      <Row>
        <label className={styles.total}>Total: {products.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>Image</td>
                <td className={styles.tableCell}>Name</td>
                <td className={styles.tableCell}>SKU</td>
                <td className={styles.tableCell}>Price</td>
                <td className={styles.tableCell}>Category</td>
                <td className={styles.tableCell}>Type</td>
                <td className={styles.tableCell}>visible</td>
                <td className={styles.tableCell}>Variables</td>
              </tr>
              {filteredProducts.map((product) => {
                return (
                  <Product
                    product={product}
                    database={database}
                    collection={collection}
                    key={~~(Math.random() * 1723917293)}
                    fetchProducts={fetchProducts}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};
export default Products;
