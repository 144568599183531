import Select from "../UI/Select";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import styles from "./Analytics.module.css";
import SearchInput from "../UI/SearchInput";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Button from "../UI/Button";
import SalesByDate from "./Date/SalesByDate";
import SalesByCustomer from "./Customer/SalesByCustomer";
import SalesByProduct from "./Product/SalesByProduct";
const Analytics = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    date: true,
    customers: false,
    products: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchRequests = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/smtp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    setIsLoading(false);
    console.log(data);
  };

  const salesByDateHandler = () => {
    setCurrentPage({
      date: true,
      customers: false,
      products: false,
    });
  };
  const salesByProductHandler = () => {
    setCurrentPage({
      date: false,
      customers: false,
      products: true,
    });
  };
  const salesByCustomerHandler = () => {
    setCurrentPage({
      date: false,
      customers: true,
      products: false,
    });
  };

  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>Analytics</h1>
      </Row>
      <Row>
        <Col xs={3}>
          <Button onClick={salesByDateHandler} text={"Sales by Date"}></Button>
        </Col>
        <Col xs={3}>
          <Button
            onClick={salesByCustomerHandler}
            text={"Sales by Customers"}
          ></Button>
        </Col>
        <Col xs={3}>
          <Button
            onClick={salesByProductHandler}
            text={"Sales by Products"}
          ></Button>
        </Col>
      </Row>
      <Row>
        {currentPage.date ? <SalesByDate /> : null}
        {currentPage.customers ? <SalesByCustomer /> : null}
        {currentPage.products ? <SalesByProduct /> : null}
      </Row>
    </Container>
  );
};

export default Analytics;
