import { Col, Row } from "react-bootstrap";
import Select from "../../UI/Select";
import SearchInput from "../../UI/SearchInput";
import Button from "../../UI/Button";
import React, { useState } from "react";
import Label from "../../UI/Label";
import TextArea from "../../UI/TextArea";
import styles from "./EditProduct.module.css";
import PDescription from "./Description/PDescription.js";
import colorOptions from "./colorOptions.json";

const EditDescription = (props) => {
  const product = props.product;
  const [description, setDescription] = useState(product.description);
  const [bulkText, setBulkText] = useState("");
  const [bulkColor, setBulkColor] = useState("");

  const bulkTextHandler = (e) => {
    setBulkText(e.target.value);
    console.log(bulkText);
  };

  const removeLineHandler = (content, j) => {
    // xd method
    const newArr = description.filter(
      (desc, i) => i !== j //desc.content !== content ||
    );
    setDescription(newArr);
  };

  const bulkColorHandler = (e) => {
    setBulkColor(e.target.value);
  };

  const addBulkTextHandler = () => {
    const bulkArray = bulkText.split("\n");
    const descriptionFormat = bulkArray.map((line) => {
      return { color: bulkColor, content: line };
    });
    setDescription([...description, ...descriptionFormat]);
  };

  const updateDescription = (index, content, color) => {
    const newDescripton = [...description];
    newDescripton[index].content = content;
    newDescripton[index].color = color;
    setDescription(newDescripton);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, index) => {
    event.preventDefault();
    const droppedRowIndex = event.dataTransfer.getData("text/plain");
    const [draggedRow] = description.splice(droppedRowIndex, 1);
    description.splice(index, 0, draggedRow);
    setDescription([...description]);
  };

  const applyChanges = async () => {
    props.update("description", description);
  };
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Button onClick={applyChanges} text={"Save Description"} />
        </Col>
      </Row>
      <Row>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell}>color</td>
              <td className={styles.tableCell} colSpan={4}>
                content
              </td>
            </tr>
            {description.map((line, i) => {
              return (
                <React.Fragment>
                  <PDescription
                    color={line.color}
                    content={line.content}
                    key={i}
                    index={i}
                    updateDescription={updateDescription}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    removeLineHandler={removeLineHandler}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </Row>
      <Row>
        <Label>Add Description</Label>
        <Col xs={3}>
          <Select
            onChangeHandler={bulkColorHandler}
            value={bulkColor}
            options={colorOptions}
            color={bulkColor}
          />
        </Col>
        <Col>
          <TextArea value={bulkText} onChangeHandler={bulkTextHandler} />
        </Col>
        <Button onClick={addBulkTextHandler} text={"add"} />
      </Row>
    </React.Fragment>
  );
};
export default EditDescription;
