import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styles from "./DetailedUser.module.css";
const DetailedUser = (props) => {
  const location = useLocation();
  const [user, setUser] = useState(location.state);

  useEffect(() => {
    setUser(location.state);
  }, [location]);
  return (
    <Container>
      <div className={styles.orderContainer}>
        <h3>
          User id: <span className={styles.default}>{user.userId} </span>
        </h3>

        <div>
          Email: <span className={styles.default}>{user.email}</span>{" "}
        </div>
        <div>
          First name: <span className={styles.default}>{user.firstName}</span>{" "}
        </div>
        <div>
          Last name: <span className={styles.default}>{user.lastName}</span>{" "}
        </div>
        <div>
          Display name:{" "}
          <span className={styles.default}>{user.displayName}</span>{" "}
        </div>
        <div>
          Role: <span className={styles.default}>{user.role}</span>{" "}
        </div>
        <div>
          Actiavated:{" "}
          <span className={styles.default}>
            {user.actiavted ? "Yes" : "No"}
          </span>{" "}
        </div>

        <div>
          Date Created:{" "}
          <span className={styles.default}>{user.dateCreated}</span>{" "}
        </div>
        <div>
          Newsletter:{" "}
          <span className={styles.default}>
            {user.newsletter ? "Yes" : "No"}
          </span>{" "}
        </div>

        <div>
          BattleNet: <span className={styles.default}>{user.battlenet}</span>{" "}
        </div>
        <div>
          Discord: <span className={styles.default}>{user.discord}</span>{" "}
        </div>
        <div>
          Country: <span className={styles.default}>{user.country}</span>{" "}
        </div>
        <div>
          inEurope:{" "}
          <span className={styles.default}>
            {user.isEuropean ? "Yes" : "No"}
          </span>{" "}
        </div>
        <div>
          IPs: <br />
          <span className={styles.default}>
            {user.ips.map((ip) => {
              return <span className={styles.default}>{ip}</span>;
            })}
          </span>{" "}
        </div>
        <div>
          Wishlists: <br />
          <span className={styles.default}>
            {user.wishlists.map((wishlist) => {
              return <span className={styles.default}>{wishlist.title}</span>;
            })}
          </span>{" "}
        </div>
        <div>
          Cart: <br />
          <span className={styles.default}>
            {user.cart.map((item) => {
              return (
                <React.Fragment>
                  <span className={styles.default}>
                    {item.product.title} - {item.realm} - x{item.quantity} - $
                    {item.product.price}/ea
                  </span>{" "}
                  <br />
                </React.Fragment>
              );
            })}
          </span>{" "}
        </div>
        <div>
          Orders: <br />
          <span className={styles.default}>
            {user.orders.map((order) => {
              return (
                <React.Fragment>
                  <span className={styles.default}>{order.id}</span> <br />
                </React.Fragment>
              );
            })}
          </span>{" "}
        </div>
      </div>
    </Container>
  );
};

export default DetailedUser;
