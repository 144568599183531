import { useNavigate } from "react-router-dom";
import styles from "./Contact.module.css";
const Contact = (props) => {
  const contact = props.contact;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/smtp/contact/response`, { state: contact });
  };
  return (
    <tr className={styles.tableRow} onClick={handleClick}>
      <td className={styles.tableCell}>{contact.id}</td>
      <td className={styles.tableCell}>{contact.name}</td>
      <td className={styles.tableCell}>{contact.subject}</td>
      <td className={styles.tableCell}>{contact.email}</td>
      <td className={styles.tableCell}>{contact.source}</td>
      <td className={styles.tableCell}>{contact.date}</td>
    </tr>
  );
};

export default Contact;
