import styles from "./Revenue.module.css";
const SingleRevenue = (props) => {
  const dayData = props.dayData;
  const [month, day, year] = dayData.day.split("/");
  const date = new Date(2000, month - 1);
  const monthName = date.toLocaleString("en-US", { month: "long" });

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableCell}>
        {day} {monthName} {year}
      </td>
      <td className={styles.tableCell}>{dayData.totalOrders}</td>
      <td className={styles.tableCell}>{dayData.grossSales.toFixed(2)}$</td>
      <td className={styles.tableCell}>{dayData.returns.toFixed(2)}$</td>
      <td className={styles.tableCell}>{dayData.coupons.toFixed(2)}$</td>
      <td className={styles.tableCell}>{dayData.netSales.toFixed(2)}$</td>
      <td className={styles.tableCell}>{dayData.tax.toFixed(2)}$</td>
      <td className={styles.tableCell}>{dayData.totalSales.toFixed(2)}$</td>
    </tr>
  );
};

export default SingleRevenue;
