import { Col, Row } from "react-bootstrap";
import Button from "../../UI/Button";
import React, { useState } from "react";
import styles from "./EditProduct.module.css";
import Variable from "./Variable";

const EditVariables = (props) => {
  const product = props.product;
  const [variables, setVariables] = useState(product.variables);

  console.log(variables);

  const edit = (method, variable) => {
    console.log(method, variable);
    if (method === "update") {
      const index = variables.findIndex((vari) => vari.id === variable.id);
      let newVari = [...variables];
      newVari[index] = variable;
      setVariables(newVari);
      console.log(newVari);
    } else if (method === "remove") {
      const newArr = variables.filter((vari) => vari.id !== variable);
      console.log(newArr);
      setVariables([...newArr]);
    }
  };
  const applyChanges = async () => {
    props.update("variables", variables);
  };

  const addNewVariable = async () => {
    const generateId = ~~(Math.random() * 10000000);
    const newVariable = {
      bind_to: 0,
      inStock: true,
      itemStats: "",
      itemPriceEUR: "",
      itemPriceUSD: "",
      itemSKU: 0,
      GGSKU: 0,
      id: generateId,
    };
    console.log([...variables, newVariable]);
    setVariables([...variables, newVariable]);
  };

  return (
    <React.Fragment>
      <Row>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableRow}>
              <td className={styles.tableCell}>visible</td>
              <td className={styles.tableCell}>GGItems SKU</td>
              <td className={styles.tableCell}>Stats</td>
              <td className={styles.tableCell}>Binded to</td>
              <td className={styles.tableCell}>Price USD</td>
              <td className={styles.tableCell}>Price EUR</td>
              <td className={styles.tableCell}>Save</td>
              <td className={styles.tableCell}>Remove</td>
              <td className={styles.tableCell}>Scrapper SKU</td>
              <td className={styles.tableCell}>Scrapper Allignment</td>
              <td className={styles.tableCell}>id</td>
            </tr>
            {variables.map((vari, i) => {
              return <Variable edit={edit} vari={vari} key={i} />;
            })}
          </tbody>
        </table>
      </Row>
      <Row>
        <Col>
          <Button onClick={addNewVariable} text={"Add new variable"} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={applyChanges} text={"Save Variables"} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EditVariables;
