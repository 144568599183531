import styles from "./SearchInput.module.css";
const SearchInput = (props) => {
  return (
    <input
      type={props.type}
      onChange={props.onChangeHandler}
      value={props.value}
      className={styles.inp}
      placeholder={props.placeholder}
      disabled={props.disabled}
      style={{ color: props.color }}
    ></input>
  );
};

export default SearchInput;
