import styles from "./Select.module.css";
const Select = (props) => {
  return (
    <select
      onChange={props.onChangeHandler}
      value={props.type}
      className={styles.select}
    >
      {props.options.map((option) => {
        return (
          <option
            key={option.id ? option.id : ~~(Math.random() * 1000)}
            value={option.value}
            style={{ color: option.value }}
          >
            {option.text ? option.text : option.value}
          </option>
        );
      })}
    </select>
  );
};

export default Select;

// <select onChange={typeHandler} value={type} className={styles.select}>
//             <option value={"Completed"}>Completed</option>
//             <option value={"Created"}>Created</option>
//             <option value={"Declined"}>Declined</option>
//             <option value={"Pending"}>Pending</option>
//           </select>
