import { useEffect, useState } from "react";
import styles from "./Gifts.module.css";
import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import Gift from "./Gift";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Select from "../UI/Select";

const modeOptions = [
  { key: 0, value: "Softcore" },
  { key: 1, value: "Hardcore" },
];
const Gifts = () => {
  const [gifts, setGifts] = useState([]);
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("Softcore");
  const navigate = useNavigate();

  useEffect(() => {
    fetchGifts();
  }, [mode]);

  useEffect(() => {
    if (searchId === "") fetchGifts();
    else {
      const filteredGifts = gifts.filter((gift) => {
        return gift.id.toLowerCase().includes(searchId.toLowerCase());
      });
      setGifts(filteredGifts);
    }
  }, [searchId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchGifts = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/gifts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mode: mode,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    setGifts(data);
  };

  const modeHandler = (e) => {
    setMode(e.target.value);
  };
  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };
  const addCouponRedirect = () => {
    navigate("./add-new-gift");
  };
  console.log(gifts);
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>Gifts</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Select
            onChangeHandler={modeHandler}
            value={mode}
            options={modeOptions}
          />
        </Col>
        <Col xs={2}>
          <Button onClick={addCouponRedirect} text={"add new gift"} />
        </Col>
        <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search gifts by id"}
          />
        </Col>
      </Row>
      <Row>
        <label className={styles.total}>Total: {gifts.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>id</td>
                <td className={styles.tableCell}>breakpoint USD</td>
                <td className={styles.tableCell}>breakpoint EUR</td>
                <td className={styles.tableCell}>Discount</td>
                <td className={styles.tableCell}>Enabled</td>
                <td className={styles.tableCell}>Date Created</td>
              </tr>
              {gifts.map((gift) => {
                return <Gift gift={gift} key={gift.id} />;
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Gifts;
