import { Col, Container, Row } from "react-bootstrap";
import styles from "./SalesByProduct.module.css";
import Title from "../../UI/Title";
import settings from "../../../settings.json";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import ProductStats from "./ProductStats";
import Button from "../../UI/Button";
import SearchInput from "../../UI/SearchInput";
const SalesByProduct = () => {
  const [defaultData, setDefaultData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async function () {
      const serverAddress = settings.serverAddress;
      setIsLoading(true);
      const response = await fetch(
        `${serverAddress}/api/admin/analytics/product`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const recievedData = await response.json();
      console.log(recievedData);
      setIsLoading(false);
      setDefaultData(recievedData);
      sortBySales();
    })();
  }, []);

  useEffect(() => {
    if (name.length === "") {
      setDisplayData(defaultData);
    } else {
      const filteredData = defaultData.filter((a) => a.name.includes(name));
      console.log(filteredData);
      setDisplayData(filteredData);
    }
  }, [name, defaultData]);

  const sortBySales = () => {
    const sortedData = defaultData.sort((a, b) => b.totalSales - a.totalSales);
    console.log(sortedData[2]);
    setDisplayData([...sortedData]);
  };
  const sortByTotalItemsSold = () => {
    const sortedData = defaultData.sort(
      (a, b) => b.totalItemsSold - a.totalItemsSold
    );
    console.log(sortedData[2]);
    setDisplayData([...sortedData]);
  };
  const serachByName = (e) => setName(e.target.value);

  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <Col>
          <Title>Top selling products</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button text={"Sort by sales"} onClick={sortBySales}></Button>
        </Col>
        <Col>
          <Button
            text={"Sort by total items sold"}
            onClick={sortByTotalItemsSold}
          ></Button>
        </Col>
        <Col>
          <SearchInput
            value={name}
            onChangeHandler={serachByName}
            placeholder={"Serach by name"}
          ></SearchInput>
        </Col>
      </Row>
      <Row>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>GGSKU</td>
                <td className={styles.tableCell}>Product Name</td>
                <td className={styles.tableCell}>Product Stats</td>
                <td className={styles.tableCell}>Realm</td>
                <td className={styles.tableCell}>Total Items Sold</td>
                <td className={styles.tableCell}>Total Sales</td>
              </tr>
              {displayData.map((a) => (
                <ProductStats data={a} key={a.id} />
              ))}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default SalesByProduct;
