import { Col, Container, Row } from "react-bootstrap";
import Label from "../../../UI/Label";
import SearchInput from "../../../UI/SearchInput";
import Button from "../../../UI/Button";
import { useEffect, useState } from "react";

const DatePicker = (props) => {
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState();
  const startDateHandler = (e) => {
    console.log(e.target.value);
    setStartDate(e.target.value);
    props.startDateHandler(e.target.value);
  };
  const endDateHandler = (e) => {
    setEndDate(e.target.value);
    props.endDateHandler(e.target.value);
  };
  useEffect(() => {
    const year = new Date().getFullYear();
    const monthNumber = new Date().getMonth() + 1;
    const day = new Date().getDate();
    if (monthNumber >= 10) {
      const formattedDate = `${year}-${monthNumber}-${day}`;
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    } else {
      const formattedDate = `${year}-0${monthNumber}-${day}`;
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    }
  }, []);
  return (
    <Container>
      <Row>
        <Col xs={4}>
          <Label>Start Date</Label>
          <SearchInput
            type="date"
            value={startDate}
            onChangeHandler={startDateHandler}
            // placeholder={"DD/MM/YYYY"}
          ></SearchInput>
        </Col>
        <Col xs={4}>
          <Label>End Date</Label>
          <SearchInput
            type="date"
            value={endDate}
            onChangeHandler={endDateHandler}
            // placeholder={"DD/MM/YYYY"}
          ></SearchInput>
        </Col>
        <Col xs={4}>
          <Label> Search</Label>
          <Button onClick={props.search} text={"Search by date"} />
        </Col>
      </Row>
    </Container>
  );
};

export default DatePicker;
