import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
const RealmsPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <h1>Not planned in version 1.0.0</h1>
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default RealmsPage;
