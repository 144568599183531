import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
import ResponseContact from "../components/Requests/ResponseContact";
const ResponseContactPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <ResponseContact />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default ResponseContactPage;
