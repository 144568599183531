import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Request.module.css";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
import Title from "../UI/Title";
import SearchInput from "../UI/SearchInput";
import TextArea from "../UI/TextArea";
import Button from "../UI/Button";
import Label from "../UI/Label";
import settings from "../../settings.json";
const ResponseRequest = (props) => {
  const location = useLocation();
  const [request, setRequest] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const [responsePrice, setResponsePrice] = useState("");

  const responseHandler = (e) => {
    setResponsePrice(e.target.value);
  };
  const respond = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(
      `${serverAddress}/api/admin/smtp/response/request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request: request,
          price: responsePrice,
        }),
      }
    );
    setIsLoading(false);
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}
      <Row>
        <Title color={"#a28648"}>Responding to request</Title>
      </Row>
      <Row>
        <Col xs={8}>id: {request.id}</Col>
      </Row>
      <Row>
        <Col xs={8}>SKU: {request.SKU}</Col>
      </Row>
      <Row>
        <Col xs={8}>item: {request.item}</Col>
      </Row>
      <Row>
        <Col xs={8}>stats: {request.stats}</Col>
      </Row>

      <Row>
        <Col xs={8}>realm: {request.realm}</Col>
      </Row>
      <Row>
        <Col xs={8}>email: {request.email}</Col>
      </Row>
      <Row>
        <Col xs={8}>date: {request.date}</Col>
      </Row>
      <br />
      <Row>
        <Col xs={8}>
          <Label>Price in USD</Label>
          <SearchInput
            value={responsePrice}
            onChangeHandler={responseHandler}
            type="number"
            placeholder={"price in USD"}
          />
        </Col>
      </Row>
      <Row>
        <Button onClick={respond} text={"Send response!"} />
      </Row>
    </Container>
  );
};

export default ResponseRequest;
