import { useNavigate } from "react-router-dom";
import styles from "./Sale.module.css";
const Sale = (props) => {
  const sale = props.sale;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/sales/${sale.id}`, { state: sale });
  };
  return (
    <tr className={styles.tableRow} onClick={handleClick}>
      <td className={styles.tableCell}>{sale.id}</td>
      <td className={styles.tableCell}>
        {sale.payment === "paid" ? (
          <span className={styles.green}>{sale.payment} </span>
        ) : (
          <span className={styles.red}>{sale.payment} </span>
        )}
      </td>
      <td className={styles.tableCell}>
        {sale.status === "completed" ? (
          <span className={styles.green}>{sale.status} </span>
        ) : null}
        {sale.status === "collecting..." ? (
          <span className={styles.blue}>{sale.status} </span>
        ) : null}
        {sale.status === "transaction rejected" ? (
          <span className={styles.red}>{sale.status} </span>
        ) : null}
        {sale.status === "collected" ? (
          <span className={styles.violet}>{sale.status} </span>
        ) : null}
      </td>
      <td className={styles.tableCell}>${sale.totalAmount.toFixed(2)}</td>
      <td className={styles.tableCell}>{sale.date}</td>
    </tr>
  );
};

export default Sale;
