import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import styles from "./Selector.module.css";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Selector from "./Selector";
const Selectors = (props) => {
  const [realms, setRealms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchRealms();
  }, []);

  const fetchRealms = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/selector`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    setIsLoading(false);
    console.log(data);
    setRealms(data);
  };

  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>Selector Panel</h1>
      </Row>

      <Row>
        <label className={styles.total}>Total: {realms.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>ID</td>
                <td className={styles.tableCell}>Title</td>
                <td className={styles.tableCell}>Mode</td>
                <td className={styles.tableCell}>Display Name</td>
                <td className={styles.tableCell}>isActive</td>
                <td className={styles.tableCell}>Date Added</td>
              </tr>
              {realms.map((realm) => {
                return <Selector realm={realm} />;
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Selectors;
