import React, { useEffect, useState } from "react";
import styles from "./ProductImage.module.css";
const ProductImage = (props) => {
  const image = props.image;
  const isRuneword = props.isRuneword;
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  useEffect(() => {
    try {
      if (isRuneword) {
        const base = require(`../../graphics${image[1]}`);
        const front = require(`../../graphics${image[0]}`);
        setFrontImage(front);
        setBackImage(base);
      } else {
        const front = require(`../../graphics${image[0]}`);
        setFrontImage(front);
      }
    } catch (err) {
      const front = require(`../../graphics/placeholder/placeholder_300x300.png`);
      setFrontImage(front);
      // setError(true);
    }
  }, [image, isRuneword]);

  return (
    <React.Fragment>
      {!isRuneword ? (
        <img
          src={frontImage} //require(`../../graphics${image[0]}`)
          alt={"front"}
          className={styles.image}
        ></img>
      ) : (
        <div className={styles.container}>
          <div
            className={styles.imgContainer}
            style={{
              backgroundImage: `url(${backImage})`, // ${require(`../../graphics${image[1]}`)}
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              objectFit: "fill",
              maxHeight: "196px",
            }}
          >
            <img
              src={frontImage} //require(`../../graphics${image[0]}`)
              alt={"front"}
              className={styles.runeImage}
            ></img>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductImage;
