import { useNavigate } from "react-router-dom";
import styles from "./Request.module.css";
const Request = (props) => {
  const request = props.request;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/smtp/request/response`, { state: request });
  };
  return (
    <tr className={styles.tableRow} onClick={handleClick}>
      <td className={styles.tableCell}>{request.id}</td>
      <td className={styles.tableCell}>{request.SKU}</td>
      <td className={styles.tableCell}>{request.item}</td>
      <td className={styles.tableCell}>{request.stats}</td>
      <td className={styles.tableCell}>{request.realm}</td>
      <td className={styles.tableCell}>{request.email}</td>
      <td className={styles.tableCell}>{request.date}</td>
    </tr>
  );
};

export default Request;
