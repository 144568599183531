import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../Analytics/Date/SalesByDate.module.css";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Title from "../UI/Title";
import TopMenu from "../Analytics/Date/TopMenu/TopMenu";
import Chart from "../Analytics/Date/Chart/Chart";
import DatePicker from "../Analytics/Date/DatePicker/DatePicker";
import Revenue from "../Analytics/Date/Revenue/Revenue";
import settings from "../../settings.json";
const HomeDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const [data, setData] = useState([]);

  const startDateHandler = (e) => {
    setStartDate(new Date(e).toLocaleDateString());
  };
  const endDateHandler = (e) => {
    setEndDate(new Date(e).toLocaleDateString());
  };

  useEffect(() => {
    (async function () {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/analytics/date`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: startDate,
        endDate: endDate,
      }),
    });
    const data = await response.json();
    console.log(data);
    setIsLoading(false);
    setData(data.reverse());
  };
  const search = async () => {
    await fetchData();
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}
      <Title> Sales from {new Date().toLocaleDateString()}</Title>
      {/* <DatePicker
        startDate={startDate}
        startDateHandler={startDateHandler}
        endDate={endDate}
        endDateHandler={endDateHandler}
        search={search}
      /> */}
      <br />
      <TopMenu data={data} />
      <br />
      {/* <Chart data={data} /> */}
      <Revenue data={data} />
    </Container>
  );
};

export default HomeDashboard;
