import styles from "./SalesByCustomer.module.css";
const Customer = (props) => {
  const data = props.data;
  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableCell}>{data.id}</td>
      <td className={styles.tableCell}>{data.firstName}</td>
      <td className={styles.tableCell}>{data.lastName}</td>
      <td className={styles.tableCell}>{data.email}</td>
      <td className={styles.tableCell}>{data.totalOrders}</td>
      <td className={styles.tableCell}>{data.totalAmount.toFixed(2)}$</td>
    </tr>
  );
};

export default Customer;
