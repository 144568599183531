import styles from "./EditProduct.module.css";
import SearchInput from "../../UI/SearchInput";
import { useState } from "react";
import Button from "../../UI/Button";
import Checkbox from "../../UI/Checkbox";

const Variable = (props) => {
  const vari = props.vari;

  const [stats, setStats] = useState(vari.itemStats);
  const [priceUSD, setPriceUSD] = useState(vari.itemPriceUSD);
  const [priceEUR, setPriceEUR] = useState(vari.itemPriceEUR);
  const [bindedTo, setBinedTo] = useState(vari.binded_to);
  const [GGItemsSKU, setGGItemsSKU] = useState(vari.GGSKU);
  const [isInStock, setIsInStock] = useState(vari.inStock);

  const statsHandler = (e) => {
    setStats(e.target.value);
  };

  const priceUSDHandler = (e) => {
    setPriceUSD(e.target.value);
  };

  const priceEURHandler = (e) => {
    setPriceEUR(e.target.value);
  };

  const bindHandler = (e) => {
    setBinedTo(e.target.value);
  };

  const SKUHandler = (e) => {
    setGGItemsSKU(e.target.value);
  };
  const inStockHandler = (e) => {
    setIsInStock(e.target.checked);
  };

  const saveHandler = () => {
    props.edit("update", {
      binded_to: bindedTo,
      itemSKU: vari.itemSKU,
      GGSKU: GGItemsSKU,
      inStock: isInStock,
      itemStats: stats,
      itemPrice: priceUSD,
      itemPriceUSD: priceUSD,
      itemPriceEUR: priceEUR,
      _itemStats: vari._itemStats,
      id: vari.id,
    });
  };
  const removeHandler = () => {
    props.edit("remove", vari.id);
  };
  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableCell}>
        <Checkbox value={isInStock} onChangeHandler={inStockHandler} />
      </td>
      <td className={styles.tableCell}>
        <SearchInput
          type="text"
          placeholder={"GGItems SKU"}
          value={GGItemsSKU}
          onChangeHandler={SKUHandler}
        />
      </td>

      <td className={styles.tableCell}>
        <SearchInput type="text" value={stats} onChangeHandler={statsHandler} />
      </td>
      <td className={styles.tableCell}>
        <SearchInput
          type="number"
          placeholder={"bind to"}
          value={bindedTo}
          onChangeHandler={bindHandler}
        />
      </td>
      <td className={styles.tableCell}>
        {/* $ */}
        <SearchInput
          type="text"
          placeholder={"usd"}
          value={priceUSD}
          onChangeHandler={priceUSDHandler}
        />
      </td>
      <td className={styles.tableCell}>
        {/* € */}
        <SearchInput
          type="text"
          placeholder={"eur"}
          value={priceEUR}
          onChangeHandler={priceEURHandler}
        />
      </td>
      <td className={styles.tableCell}>
        <Button onClick={saveHandler} text={"save"} />
      </td>
      <td className={styles.tableCell}>
        <Button onClick={removeHandler} text={"remove"} />
      </td>
      <td className={styles.tableCell}>{vari.itemSKU}</td>
      <td className={styles.tableCell}>{vari._itemStats}</td>
      <td className={styles.tableCell}>{vari.id}</td>
    </tr>
  );
};

export default Variable;
