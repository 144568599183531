import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import Select from "../UI/Select";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useEffect, useState } from "react";
import Label from "../UI/Label";
import TextArea from "../UI/TextArea";
import Title from "../UI/Title";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Checkbox from "../UI/Checkbox";
import Collections from "./Collections";

const EditCoupon = () => {
  const location = useLocation();
  const [coupon, setCoupon] = useState(location.state);
  const navigate = useNavigate();

  useEffect(() => {
    setCoupon(location.state);
  }, [location]);

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState(coupon.code);
  const [description, setDescription] = useState(coupon.description);
  const [type, setType] = useState(coupon.type);
  const [percantageOff, setPercentageOff] = useState(coupon.percentageOff);
  const [amountOffUSD, setAmountOffUSD] = useState(coupon.amountOffUSD);
  const [amountOffEUR, setAmountOffEUR] = useState(coupon.amountOffEUR);
  const [startDate, setStartDate] = useState(coupon.startDate);
  const [expiryDate, setExpiryDate] = useState(coupon.expiryDate);
  const [neverEnds, setNeverEnds] = useState(coupon.neverEnds);
  const [includeAllCollections, setIncludeAllCollections] = useState(
    coupon.includeAllCollections
  );
  const [selectedCollections, setSelectedCollections] = useState(
    coupon.selectedCollections
  );
  const [minimumSpendUSD, setMinimumSpendUSD] = useState(
    coupon.minimumSpendUSD
  );
  const [minimumSpendEUR, setMinimumSpendEUR] = useState(
    coupon.minimumSpendEUR
  );
  const [maxTimesUsed, setMaxTimesUsed] = useState(coupon.maxTimesUsed);
  const [isLimited, setIsLimited] = useState(coupon.isLimited);

  const codeHandler = (e) => {
    setCode(e.target.value);
  };
  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };
  const amountHandler = (e) => {
    setAmountOffUSD(e.target.value);
    const eur = (+e.target.value * 0.93).toFixed(2);
    console.log(eur);
    setAmountOffEUR(eur);
  };
  const percentageHandler = (e) => {
    setPercentageOff(e.target.value);
  };
  const neverEndsHandler = (e) => {
    setNeverEnds(e.target.checked);
    console.log(neverEnds);
  };

  const startDateHandler = (e) => {
    setStartDate(e.target.value);
  };
  const expireHandler = (e) => {
    setExpiryDate(e.target.value);
  };

  const minimumHandler = (e) => {
    setMinimumSpendUSD(e.target.value);
    const eur = (+e.target.value * 0.93).toFixed(2);
    setMinimumSpendEUR(eur);
  };

  const allCollectionsHandler = (e) => {
    setIncludeAllCollections(e.target.checked);
  };
  const generateCodeHandler = () => {
    let randomCode = "GGIC-";
    for (let i = 0; i < 5; i++) {
      randomCode += ~~(Math.random() * 10);
    }
    setCode(randomCode);
  };

  const collectionHandler = (name, action) => {
    if (action) {
      setSelectedCollections([...selectedCollections, name]);
    } else {
      const index = selectedCollections.findIndex((el) => el === name);
      selectedCollections.splice(index, 1);
      setSelectedCollections([...selectedCollections]);
    }
  };

  const maxTimesHandler = (e) => {
    setMaxTimesUsed(e.target.value);
  };

  const limitHandler = (e) => {
    setIsLimited(e.target.checked);
    if (e.target.checked) setMaxTimesUsed(0);
  };
  const editCouponHandler = async () => {
    const updatedCoupon = {
      code: code,
      description: description,
      type: type,
      percantageOff: +percantageOff,
      amountOffUSD: +amountOffUSD,
      amouuntOffEUR: +amountOffEUR,
      startDate: startDate,
      expiryDate: expiryDate,
      neverEnds: neverEnds,
      minimumSpendUSD: +minimumSpendUSD,
      minimumSpendEUR: +minimumSpendEUR,
      includeAllCollections: includeAllCollections,
      selectedCollections: selectedCollections,
      isLimited: isLimited,
      maxTimesUsed: +maxTimesUsed,
    };
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    /*const response = */ await fetch(
      `${serverAddress}/api/admin/coupons/edit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: updatedCoupon,
        }),
      }
    );
    // const data = await response.text();
    setIsLoading(false);
    navigate("/coupons");
  };

  const disableHandler = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    /*const response =*/ await fetch(
      `${serverAddress}/api/admin/coupons/toggle`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: coupon,
        }),
      }
    );
    // const data = await response.text();
    setIsLoading(false);
    navigate("/coupons");
  };

  const removeHandler = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    /*const response =*/ await fetch(
      `${serverAddress}/api/admin/coupons/remove`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: coupon,
        }),
      }
    );
    // const data = await response.text();
    setIsLoading(false);
    navigate("/coupons");
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <Title>Create New Coupon</Title>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Code:</Label>
          <SearchInput
            type="text"
            value={code}
            onChangeHandler={codeHandler}
            placeholder={"discourd code"}
          />
        </Col>
        <Col xs={2}>
          <Label>Random Code</Label>
          <Button onClick={generateCodeHandler} text={"Generate"}></Button>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Label>Description:</Label>
          <TextArea
            value={description}
            onChangeHandler={descriptionHandler}
            placeholder={"coupon description"}
          />
        </Col>
      </Row>
      <Title>General</Title>
      <Row>
        <Col xs={8}>
          <Label>Counpon type: {type}</Label>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={[
              { id: 1, value: "percentage off" },
              { id: 2, value: "amount off" },
            ]}
          />
        </Col>
      </Row>
      {type === "percentage off" ? (
        <Row>
          <Col xs={8}>
            <Label>Percentage off</Label>
            <SearchInput
              type="number"
              value={percantageOff}
              onChangeHandler={percentageHandler}
              placeholder={"percentage off"}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={4}>
            <Label>Coupon Amount USD</Label>
            <SearchInput
              type="number"
              value={amountOffUSD}
              onChangeHandler={amountHandler}
              placeholder={type}
            />
          </Col>
          <Col xs={4}>
            <Label>Coupon Amount EUR</Label>
            <SearchInput
              type="number"
              value={amountOffEUR}
              onChangeHandler={amountHandler}
              placeholder={type}
              disabled={true}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={8}>
          <Label>Start Date</Label>
          <SearchInput
            type="date"
            value={startDate}
            onChangeHandler={startDateHandler}
            placeholder={"DD/MM/YYYY"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Expire Date</Label>
          <SearchInput
            type="date"
            value={expiryDate}
            onChangeHandler={expireHandler}
            placeholder={"DD/MM/YYYY"}
            disabled={neverEnds}
          />
        </Col>
        <Col xs={2}>
          <Label>Never Ends</Label>
          <Checkbox value={neverEnds} onChangeHandler={neverEndsHandler} />
        </Col>
      </Row>
      <Title>Coupon Restrictions</Title>
      <Row>
        <Col xs={4}>
          <Label>Minimum Spend USD</Label>
          <SearchInput
            type="number"
            value={minimumSpendUSD}
            onChangeHandler={minimumHandler}
            placeholder={"Minimum amount to spend"}
          />
        </Col>
        <Col xs={4}>
          <Label>Minimum Spend EUR</Label>
          <SearchInput
            type="number"
            value={minimumSpendEUR}
            onChangeHandler={minimumHandler}
            placeholder={"Minimum amount to spend"}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Selected Collections</Label>
          <Collections
            select={collectionHandler}
            includeAll={includeAllCollections}
          />
        </Col>
        <Col xs={2}>
          <Label>Include All Collections</Label>
          <Checkbox
            value={includeAllCollections}
            onChangeHandler={allCollectionsHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Label>Max times used</Label>
          <SearchInput
            type="number"
            value={maxTimesUsed}
            onChangeHandler={maxTimesHandler}
            placeholder={"max times used"}
            disabled={isLimited}
          />
        </Col>
        <Col xs={2}>
          <Label>Not limited</Label>
          <Checkbox value={isLimited} onChangeHandler={limitHandler} />
        </Col>
      </Row>

      <Row>
        <Col xs={8}>
          <Title></Title>
          <Button onClick={editCouponHandler} text={"Edit Coupon"}></Button>
          <Button
            onClick={disableHandler}
            text={coupon.active ? "Disable Coupon" : "Enable Coupon"}
          ></Button>
          <Button onClick={removeHandler} text={"Remove Coupon"}></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EditCoupon;
