import React from "react";

const ColorText = (props) => {
  const color = props.color;
  let text;
  switch (color) {
    case "#FF4D4D":
      text = "Red";
      break;
    case "#C7B377":
      text = "Unique";
      break;
    case "#6969FF":
      text = "Magic";
      break;
    case "#696969":
      text = "Socket";
      break;
    case "#00FF00":
      text = "Set";
      break;
    case "#FFA800":
      text = "Craft";
      break;
    case "#FFFF64":
      text = "Rare";
      break;
    case "#FFFFFF":
      text = "Normal Text";
      break;
    default:
      text = "Sample Text";
      break;
  }
  return <span style={{ color: color }}>{text}</span>;
};
export default ColorText;
