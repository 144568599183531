import styles from "./Title.module.css";
const Title = (props) => {
  return (
    <h2
      className={styles.title}
      style={props.color ? { color: props.color } : null}
    >
      {props.children}
    </h2>
  );
};

export default Title;
