import React from "react";
import styles from "./Label.module.css";
const Label = (props) => {
  return (
    <React.Fragment>
      <label className={styles.label}>{props.children}</label> <br />
    </React.Fragment>
  );
};

export default Label;
