import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import styles from "./UsersPage.module.css";
import Dashboard from "../components/UI/Dashboard";
import Users from "../components/Users/Users";
const UsersPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <Users />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default UsersPage;
