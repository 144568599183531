import settings from "../../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Title from "../../UI/Title";
import { useLocation } from "react-router-dom";
import EditImage from "./EditImage";
import EditVariables from "./EditVariables";
import EditDescription from "./EditDescription";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import Alert from "../../UI/Alert";
import SearchInput from "../../UI/SearchInput";
import Button from "../../UI/Button";
const EditProduct = () => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const location = useLocation();
  const [product, setProduct] = useState(location.state.product);
  const [itemTitle, setItemTitle] = useState(location.state.product.title);
  const [isLoading, setIsLoading] = useState(false);
  const database = location.state.database;
  const collection = location.state.collection;
  useEffect(() => {
    setProduct(location.state.product);
  }, [location]);

  console.log(product);

  const titleHandler = (e) => {
    setItemTitle(e.target.value);
  };

  const saveTitle = () => {
    setItemTitle(itemTitle);
    fetchEdit("title", itemTitle);
  };

  const fetchEdit = async (action, data) => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/products/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        database: database,
        collection: collection,
        product: product,
        action: action,
        data: data,
      }),
    });
    setIsLoading(false);
    const r = await response.text();

    if (r === "image") {
      setAlertVisible({
        visible: true,
        message: "Product Image has been updated!",
      });
      setTimeout(() => {
        setAlertVisible({
          visible: false,
          message: "",
        });
      }, 3000);
    } else if (r === "description") {
      setAlertVisible({
        visible: true,
        message: "Product Description has been updated!",
      });
      setTimeout(() => {
        setAlertVisible({
          visible: false,
          message: "",
        });
      }, 3000);
    } else if (r === "variables") {
      setAlertVisible({
        visible: true,
        message: "Product  Variables has been updated!",
      });
      setTimeout(() => {
        setAlertVisible({
          visible: false,
          message: "",
        });
      }, 3000);
    }
  };

  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}
      <Row>
        <Title color={"#a28648"}>{location.state.product.title}</Title>
      </Row>
      <Row>
        <Col xs={8}>id: {product.id}</Col>
      </Row>
      <Row>
        <Col xs={8}>Database: {database}</Col>
      </Row>
      <Row>
        <Col xs={8}>Collection: {collection}</Col>
      </Row>

      <Row>
        <Col xs={8}>Category: {product.category}</Col>
      </Row>
      <Row>
        <Col xs={8}>Type: {product.type}</Col>
      </Row>
      <br />
      <Row>
        <Col xs={8}>
          <SearchInput
            type="text"
            value={itemTitle}
            onChangeHandler={titleHandler}
            placeholder={"item title"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Button text={"Save Title"} onClick={saveTitle} />
        </Col>
      </Row>

      <Row>
        <Title>Image</Title>
      </Row>
      <EditImage product={product} update={fetchEdit} />
      <Row>
        <Title>Variables</Title>
      </Row>
      <EditVariables product={product} update={fetchEdit} />
      <Row>
        <Title>Description</Title>
      </Row>
      <Row>
        <Col xs={8}>
          <EditDescription product={product} update={fetchEdit} />
        </Col>
      </Row>
      <Row>
        <Title>Scrapper Allignment</Title>
      </Row>
      <Row>
        <Col xs={8}>_title: {product._title}</Col>
      </Row>
    </Container>
  );
};

export default EditProduct;
