import Select from "../UI/Select";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import styles from "./Requests.module.css";
import SearchInput from "../UI/SearchInput";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Request from "./Request";
import Contact from "./Contact";
const Requests = (props) => {
  // const params = useParams();
  // const { collection, category, type } = params;

  const [requests, setRequests] = useState([]);
  const [type, setType] = useState("Requests");
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectOptions = [
    { id: 1, value: "Requests" },
    { id: 2, value: "Contact" },
  ];
  useEffect(() => {
    fetchRequests();
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchId === "") fetchRequests();
    else {
      const filteredSales = requests.filter((request) => {
        return request.id.toLowerCase().includes(searchId.toLowerCase());
      });
      setRequests(filteredSales);
    }
  }, [searchId]);

  const fetchRequests = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/smtp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state: type,
      }),
    });
    const data = await response.json();
    setIsLoading(false);
    console.log(data);
    setRequests(data.reverse());
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };
  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <h1>SMTP</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={selectOptions}
          />
        </Col>
        <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search sales by id"}
          />
        </Col>
      </Row>
      <Row>
        <label className={styles.total}>Total: {requests.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              {type === "Requests" ? (
                <React.Fragment>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell}>ID</td>
                    <td className={styles.tableCell}>SKU</td>
                    <td className={styles.tableCell}>Item</td>
                    <td className={styles.tableCell}>Stats</td>
                    <td className={styles.tableCell}>Realm</td>
                    <td className={styles.tableCell}>Email</td>
                    <td className={styles.tableCell}>Date</td>
                  </tr>
                  {requests.map((request) => {
                    return <Request request={request} />;
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableCell}>ID</td>
                    <td className={styles.tableCell}>Name</td>
                    <td className={styles.tableCell}>Subject</td>
                    <td className={styles.tableCell}>Email</td>
                    <td className={styles.tableCell}>Source</td>
                    <td className={styles.tableCell}>Date</td>
                  </tr>
                  {requests.map((contact) => {
                    return <Contact contact={contact} />;
                  })}
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Requests;
