import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
import EditCoupon from "../components/coupons/EditCoupon";
const EditCouponPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <EditCoupon />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default EditCouponPage;
