import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./DetailedSale.module.css";
import Button from "../UI/Button";
import settings from "../../settings.json";
import LoadingSpinner from "../Spinner/LoadingSpinner";
const DetailedSale = (props) => {
  const location = useLocation();
  const [order, setOrder] = useState(location.state);
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setOrder(location.state);
  }, [location]);

  const markAsCollected = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/sales/collected`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: order.id,
      }),
    });
    setIsLoading(false);
    navigate("/sales");
  };

  const markAsCompleted = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/sales/completed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: order.id,
      }),
    });
    setIsLoading(false);
    navigate("/sales");
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}

      <div className={styles.orderContainer}>
        <h3>
          Order id: <span className={styles.default}>{order.id} </span>
        </h3>
        <div>Total Amount: ${order.totalAmount}</div>

        <div>
          Payment:{" "}
          {order.payment === "paid" ? (
            <span className={styles.green}>{order.payment} </span>
          ) : (
            <span className={styles.red}>{order.payment} </span>
          )}
        </div>
        <div>
          Status:{" "}
          {order.status === "completed" ? (
            <span className={styles.green}>{order.status} </span>
          ) : null}
          {order.status === "collecting..." ? (
            <span className={styles.blue}>{order.status} </span>
          ) : null}
          {order.status === "transaction rejected" ? (
            <span className={styles.red}>{order.status} </span>
          ) : null}
          {order.status === "collected" ? (
            <span className={styles.violet}>{order.status} </span>
          ) : null}
        </div>
        <div>
          Date: <span className={styles.default}> {order.date}</span>{" "}
        </div>
        <h2>Customer Details:</h2>
        <div>
          User ID:{" "}
          <span className={styles.default}>{order.customer.userId}</span>{" "}
        </div>

        <div>
          Email: <span className={styles.default}>{order.customer.email}</span>{" "}
        </div>
        <div>
          First name:{" "}
          <span className={styles.default}>{order.customer.firstName}</span>{" "}
        </div>
        <div>
          Last name:{" "}
          <span className={styles.default}>{order.customer.lastName}</span>{" "}
        </div>
        <div>
          IP: <span className={styles.default}>{order.customer.ip}</span>{" "}
        </div>
        <div>
          BattleNet:{" "}
          <span className={styles.default}>{order.customer.battlenet}</span>{" "}
        </div>
        <div>
          Discord:{" "}
          <span className={styles.default}>{order.customer.discord}</span>{" "}
        </div>
        <div>
          Country:{" "}
          <span className={styles.default}>{order.customer.country}</span>{" "}
        </div>
        <div>
          inEurope:{" "}
          <span className={styles.default}>
            {order.customer.isEuropean ? "Yes" : "No"}
          </span>{" "}
        </div>

        <div>
          <h2>Receipt:</h2>
          <h5>Total Items: {order.items.length}</h5>
          <div className={styles.tableCont}>
            <table>
              <tbody>
                {order.items.map((item) => {
                  return (
                    <tr className={styles.tableRow} key={item.name}>
                      <td className={styles.tableCell}>{item.name}</td>
                      <td className={styles.tableCell}>x{item.quantity}</td>
                      <td className={styles.tableCell}>
                        ${+item.priceInCents / 100}/ea
                      </td>
                    </tr>
                  );
                })}
                {/* <tr className={styles.tableRow}>
                        <td className={styles.tableCell} colSpan={2}></td>
                        <td className={styles.tableCell}>
                          {/* total: ${(+cartCtx.totalAmount).toFixed(2)} */}
                {/* </td> */}
                {/* </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Row>
        <Col>
          {order.status === "collecting..." ? (
            <Button
              text={"Mark as collected, ready for delivery"}
              onClick={markAsCollected}
            ></Button>
          ) : (
            <Button
              text={"Mark as completed, order delivered"}
              onClick={markAsCompleted}
            ></Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DetailedSale;
