import { Link, useNavigate } from "react-router-dom";
import styles from "./Gift.module.css";
import Checkbox from "../UI/Checkbox";
import React, { useState } from "react";
import settings from "../../settings.json";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
const Gift = (props) => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const gift = props.gift;
  const [isEnabled, setIsEnabled] = useState(gift.active);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/gifts/edit/${gift.id}`, { state: gift });
  };

  const enabledHandler = async (e) => {
    setIsEnabled(e.target.checked);

    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    await fetch(`${serverAddress}/api/admin/gifts/toggle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        giftId: gift.id,
        active: !isEnabled,
      }),
    });
    setIsLoading(false);
    setAlertVisible({
      visible: true,
      message: "Gift has status has been changed!",
    });
    setTimeout(() => {
      setAlertVisible(
        {
          visible: false,
          message: "",
        },
        3000
      );
    });
  };
  return (
    <React.Fragment>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}

      <tr className={styles.tableRow} onClick={handleClick}>
        <td className={styles.tableCell}>{gift.id}</td>
        <td className={styles.tableCell}>
          ${Number(gift.breakpointUSD).toFixed(2)}
        </td>

        <td className={styles.tableCell}>
          €{Number(gift.breakpointEUR).toFixed(2)}
        </td>
        <td className={styles.tableCell}>{gift.discount}%</td>
        <td className={styles.tableCell}>
          <Checkbox value={isEnabled} onChangeHandler={enabledHandler} />
        </td>
        <td className={styles.tableCell}>{gift.dateCreated}</td>
      </tr>
    </React.Fragment>
  );
};
export default Gift;
