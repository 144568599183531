import { useNavigate } from "react-router-dom";
import styles from "./Coupon.module.css";
const Coupon = (props) => {
  const coupon = props.coupon;
  console.log(coupon);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/coupons/edit/${coupon.id}`, { state: coupon });
  };
  return (
    <tr className={styles.tableRow} onClick={handleClick}>
      <td className={styles.tableCell}>{coupon.code}</td>
      <td className={styles.tableCell}>{coupon.type}</td>
      <td className={styles.tableCell}>
        {coupon.type === "percentage off"
          ? `${coupon.percantageOff}%`
          : `${coupon.amountOffUSD}$`}
      </td>
      <td className={styles.tableCell}>
        {coupon.timesUsed} / {coupon.maxTimesUsed}
      </td>
      <td className={styles.tableCell}>
        {coupon.active ? (
          <span className={styles.green}>Yes</span>
        ) : (
          <span className={styles.red}>No</span>
        )}
      </td>
      <td className={styles.tableCell}>{coupon.expiryDate}</td>
    </tr>
  );
};
export default Coupon;
