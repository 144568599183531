import styles from "./SalesByProduct.module.css";
const ProductStats = (props) => {
  const data = props.data;
  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableCell}>{data.GGSKU}</td>
      <td className={styles.tableCell}>{data.name}</td>
      <td className={styles.tableCell}>{data.stats}</td>
      <td className={styles.tableCell}>{data.realm}</td>
      <td className={styles.tableCell}>{data.totalItemsSold}</td>
      <td className={styles.tableCell}>{data.totalSales.toFixed(2)}$</td>
    </tr>
  );
};

export default ProductStats;
