import React, { useState } from "react";
import Options from "../Products/Options.json";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../UI/Checkbox";
import Collection from "./Collection";
const Collections = (props) => {
  const sortedOptions = Options.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  return (
    <React.Fragment>
      <Row>
        {sortedOptions.map((opt) => {
          return (
            <Collection
              select={props.select}
              includeAll={props.includeAll}
              title={opt.title}
            />
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default Collections;
