import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import Label from "../UI/Label";
import Title from "../UI/Title";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddFee = () => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [breakpointUSD, setBreakpointUSD] = useState(0);
  const [amountUSD, setAmountUSD] = useState(0);
  const [breakpointEUR, setBreakpointEUR] = useState(0);
  const [amountEUR, setAmountEUR] = useState(0);

  const navigate = useNavigate();

  const breakpointUSDHandler = (e) => {
    setBreakpointUSD(+e.target.value);
  };
  const amountUSDHandler = (e) => {
    setAmountUSD(+e.target.value);
  };
  const breakpointEURHandler = (e) => {
    setBreakpointEUR(+e.target.value);
  };
  const amountEURHandler = (e) => {
    setAmountEUR(+e.target.value);
  };

  const createGiftHandler = async () => {
    if (
      breakpointEUR === 0 ||
      breakpointUSD === 0 ||
      amountEUR === 0 ||
      amountUSD === 0
    ) {
      setAlertVisible({
        visible: true,
        message: "Discount or breakpoint is equal 0",
      });
      setTimeout(() => {
        setAlertVisible({
          visible: false,
          message: "",
        });
      }, 3000);
      return;
    }

    const fee = { breakpointUSD, amountUSD, breakpointEUR, amountEUR };
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    console.log(fee);
    await fetch(`${serverAddress}/api/admin/fees/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fee: fee,
      }),
    });
    setIsLoading(false);
    navigate("/fees");
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}

      <Row>
        <Title>Create New Fee</Title>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        <Title>USD</Title>
      </Row>
      <Row>
        <Col xs={9}>
          <Label>Breakpoint (USD)</Label>
          <SearchInput
            type="number"
            value={breakpointUSD}
            onChangeHandler={breakpointUSDHandler}
            placeholder={"breakpoint to apply fee"}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={9}>
          <Label>Amount to Add (USD)</Label>
          <SearchInput
            type="number"
            value={amountUSD}
            onChangeHandler={amountUSDHandler}
            placeholder={"amount to add"}
          />
        </Col>
      </Row>
      <Row>
        <Title>EUR</Title>
      </Row>
      <Row>
        <Col xs={9}>
          <Label>Breakpoint (EUR)</Label>
          <SearchInput
            type="number"
            value={breakpointEUR}
            onChangeHandler={breakpointEURHandler}
            placeholder={"breakpoint to apply fee"}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={9}>
          <Label>Amount to Add (EUR)</Label>
          <SearchInput
            type="number"
            value={amountEUR}
            onChangeHandler={amountEURHandler}
            placeholder={"amount to add"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Title></Title>
          <Button onClick={createGiftHandler} text={"Create fee"}></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddFee;
