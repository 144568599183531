import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../sidebar/Sidebar";
import styles from "./Dashboard.module.css";

const Dashboard = (props) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className={styles.sidebarWrapper} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} className={styles.pageWrapper} id="page-content-wrapper">
            {props.children}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Dashboard;
