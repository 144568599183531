import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
import Sales from "../components/Sales/Sales";
const SalesPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <Sales />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default SalesPage;
