import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProductsPage from "./pages/ProductsPage";
import UsersPage from "./pages/UsersPage";
import ContentPage from "./pages/ContentPage";
import CouponsPage from "./pages/CouponsPage";
import RealmsPage from "./pages/RealmsPage";
import SalesPage from "./pages/SalesPage";
import GiftPage from "./pages/GiftPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import LoginPage from "./pages/LoginPage";
import DetailedSalePage from "./pages/DetailedSalePage";
import DetailedUserPage from "./pages/DetailedUserPage";
import NewCouponPage from "./pages/NewCouponPage";
import EditCouponPage from "./pages/EditCouponPage";
import EditProductPage from "./pages/EditProductPage";
import AuthProvider from "./components/context/AuthProvider";
import NewGiftPage from "./pages/NewGiftPage";
import EditGiftPage from "./pages/EditGiftPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import NewProductPage from "./pages/NewProductPage";
import FeePage from "./pages/FeePage";
import NewFeePage from "./pages/NewFeePage";
import SectionsPage from "./pages/SectionsPage";
import RequestsPage from "./pages/RequestsPage";
import ResponseRequestPage from "./pages/ResponseRequestPage";
import ResponseContactPage from "./pages/ResponseContactPage";
import SelectorPage from "./pages/SelectorPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route
            path={"/dashboard"}
            element={
              <ProtectedRoute>
                <HomePage></HomePage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={"/products"}
            element={
              <ProtectedRoute>
                <ProductsPage></ProductsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={"/products/edit/:productId"}
            element={
              <ProtectedRoute>
                <EditProductPage></EditProductPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={"/products/add-new"}
            element={
              <ProtectedRoute>
                <NewProductPage></NewProductPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={"/users"}
            element={
              <ProtectedRoute>
                <UsersPage></UsersPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/users/:userId"}
            element={
              <ProtectedRoute>
                <DetailedUserPage></DetailedUserPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/content"}
            element={
              <ProtectedRoute>
                <ContentPage></ContentPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/coupons"}
            element={
              <ProtectedRoute>
                <CouponsPage></CouponsPage>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            // exact
            path={"/coupons/add-new-coupon"}
            element={
              <ProtectedRoute>
                <NewCouponPage></NewCouponPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/coupons/edit/:couponId"}
            element={
              <ProtectedRoute>
                <EditCouponPage></EditCouponPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/realms"}
            element={
              <ProtectedRoute>
                <RealmsPage></RealmsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/sales"}
            element={
              <ProtectedRoute>
                <SalesPage></SalesPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/sales/:saleId"}
            element={
              // <ProtectedRoute>
              <DetailedSalePage></DetailedSalePage>
              // </ProtectedRoute>
            }
          ></Route>
          <Route
            //  exact
            path={"/gifts"}
            element={
              <ProtectedRoute>
                <GiftPage></GiftPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            //  exact
            path={"/gifts/add-new-gift"}
            element={
              <ProtectedRoute>
                <NewGiftPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            //  exact
            path={"/gifts/edit/:giftId"}
            element={
              <ProtectedRoute>
                <EditGiftPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/analytics"}
            element={
              <ProtectedRoute>
                <AnalyticsPage></AnalyticsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/fees"}
            element={
              <ProtectedRoute>
                <FeePage></FeePage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/fees/add"}
            element={
              <ProtectedRoute>
                <NewFeePage></NewFeePage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/selector"}
            element={
              <ProtectedRoute>
                <SelectorPage></SelectorPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/smtp"}
            element={
              <ProtectedRoute>
                <RequestsPage></RequestsPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/smtp/request/response"}
            element={
              <ProtectedRoute>
                <ResponseRequestPage></ResponseRequestPage>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            // exact
            path={"/smtp/contact/response"}
            element={
              <ProtectedRoute>
                <ResponseContactPage></ResponseContactPage>
              </ProtectedRoute>
            }
          ></Route>
          {/* </Route> */}
          <Route exact path={"/"} element={<LoginPage></LoginPage>}></Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
