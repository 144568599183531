import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Request.module.css";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
import Title from "../UI/Title";
import SearchInput from "../UI/SearchInput";
import TextArea from "../UI/TextArea";
import Button from "../UI/Button";
const ResponseContact = (props) => {
  const location = useLocation();
  const [contact, setRequest] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const [response, setResponse] = useState("");

  const responseHandler = (e) => {
    setResponse(e.target.value);
  };
  const respond = () => {
    console.log(response);
    // TODO
    // BACKEND FETCH
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}
      <Row>
        <Title color={"#a28648"}>Responding to Contact</Title>
      </Row>
      <Row>
        <Col xs={8}>id: {contact.id}</Col>
      </Row>
      <Row>
        <Col xs={8}>name: {contact.name}</Col>
      </Row>
      <Row>
        <Col xs={8}>email: {contact.email}</Col>
      </Row>
      <Row>
        <Col xs={8}>subject: {contact.subject}</Col>
      </Row>

      <Row>
        <Col xs={8}>source: {contact.source}</Col>
      </Row>
      <Row>
        <Col xs={8}>email: {contact.email}</Col>
      </Row>
      <Row>
        <Col xs={8}>date: {contact.date}</Col>
      </Row>
      <Row>
        <Col xs={8}>Message: {contact.date}</Col>
      </Row>
      <Row>
        <Col xs={8}>{contact.message}</Col>
      </Row>
      <br />
      <Row>
        <Col xs={8}>
          <TextArea value={response} onChangeHandler={responseHandler} />
        </Col>
      </Row>
      <Row>
        <Button onClick={respond} text={"Send response!"} />
      </Row>
    </Container>
  );
};

export default ResponseContact;
