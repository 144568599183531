import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "../UI/Checkbox";
import styles from "./Product.module.css";
import ProductImage from "./ProductImage";
import settings from "../../settings.json";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Button from "../UI/Button";
import { Row, Col } from "react-bootstrap";
import CustomConfirm from "../UI/CustomConfirm";

const Product = (props) => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const product = props.product;
  const isRuneword = props.product.image.length > 1;
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInStock, setIsInStock] = useState(product.inStock);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [priceRange, setPriceRange] = useState("");
  const [SKURange, setSKURange] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let price = [];
    if (product.variables.length > 0) {
      for (const v of product.variables) {
        if (v.itemPriceUSD !== "request") price.push(Number(v.itemPriceUSD));
      }

      console.log(price);

      const sku = product.variables.map((v) => {
        return Number(v.GGSKU);
      });

      const minPrice = Math.min(...price);
      const maxPrice = Math.max(...price);
      const minSKU = product.variables[0].GGSKU;
      const maxSKU = product.variables[product.variables.length - 1].GGSKU;
      setPriceRange(`${minPrice}$ - ${maxPrice}$`);
      setSKURange(`${minSKU} ~ ${maxSKU}`);
    } else {
      setPriceRange(`0-0`);
      setSKURange(`0-0`);
    }
  }, []);
  const handleClick = () => {
    navigate(`/products/edit/${product.title}`, {
      state: {
        product: product,
        database: props.database,
        collection: props.collection,
      },
    });
  };

  const duplicateHandler = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(
      `${serverAddress}/api/admin/products/duplicate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          database: props.database,
          collection: props.collection,
          id: product.id,
        }),
      }
    );
    const r = await response.text();
    setIsLoading(false);
    setAlertVisible({
      visible: true,
      message: "Product stock has been duplicated!",
    });
    setTimeout(() => {
      setAlertVisible({
        visible: false,
        message: "",
      });
    }, 3000);
    props.fetchProducts();
  };

  const onClose = () => {
    setIsConfirmed(false);
  };

  const confirmDelete = () => {
    setIsConfirmed(true);
  };

  const deleteHandler = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/products/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        database: props.database,
        collection: props.collection,
        id: product.id,
      }),
    });
    const r = await response.text();
    setIsLoading(false);
    setIsConfirmed(false);
    setAlertVisible({
      visible: true,
      message: "Product stock has been deleted!",
    });
    setTimeout(() => {
      setAlertVisible({
        visible: false,
        message: "",
      });
    }, 3000);

    props.fetchProducts();
  };

  const viewHandler = () => {};

  const inStockHandler = async (e) => {
    setIsInStock(e.target.checked);
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/products/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        database: props.database,
        collection: props.collection,
        product: product,
        action: "toggleStock",
        data: !isInStock,
      }),
    });
    const r = await response.text();
    setIsLoading(false);
    setAlertVisible({
      visible: true,
      message: "Product stock has been changed!",
    });
    setTimeout(() => {
      setAlertVisible({
        visible: false,
        message: "",
      });
    }, 3000);
  };

  return (
    <React.Fragment>
      {isLoading ? <LoadingSpinner /> : null}
      {isConfirmed ? (
        <CustomConfirm
          deleteHandler={deleteHandler}
          onClose={onClose}
          title={product.title}
        />
      ) : null}
      <tr
        className={styles.tableRow}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <td className={styles.tableCell}>
          <ProductImage isRuneword={isRuneword} image={product.image} />
        </td>
        <td className={styles.tableCell}>{product.title}</td>
        <td className={styles.tableCell}>{SKURange}</td>
        <td className={styles.tableCell}>{priceRange}</td>

        <td className={styles.tableCell}>{product.category}</td>
        <td className={styles.tableCell}>{product.type}</td>
        <td className={styles.tableCell}>
          <Checkbox value={isInStock} onChangeHandler={inStockHandler} />
        </td>
        <td className={styles.tableCell}>{product.variables.length}</td>
      </tr>
      {isHovering ? (
        <tr
          style={{ textAlign: "left" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <td className={styles.tableCell} colSpan={8}>
            <Row>
              <Col>
                <Button onClick={handleClick} text={"Edit"}></Button>
              </Col>
              <Col>
                <Button onClick={viewHandler} text={"View"}></Button>
              </Col>
              <Col>
                <Button onClick={duplicateHandler} text={"Duplicate"}></Button>
              </Col>
              <Col>
                <Button onClick={confirmDelete} text={"Delete"}></Button>
              </Col>
            </Row>
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  );
};

export default Product;
