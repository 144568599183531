import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm from "../components/Login/LoginForm";
const LoginPage = () => {
  return (
    <Container style={{ backgroundColor: "#424141", height: "100vh" }} fluid>
      <Row>
        <Col>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
};
export default LoginPage;
