import settings from "../../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Title from "../../UI/Title";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import Alert from "../../UI/Alert";
import SearchInput from "../../UI/SearchInput";
import Button from "../../UI/Button";
import Select from "../../UI/Select";
import Options from "../Options.json";
const AddProduct = () => {
  const sortedOptions = Options.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });

  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const databaseOptions = [
    { id: 1, value: "ProductsD2RLadder" },
    { id: 2, value: "ProductsD2RNLadder" },
  ];

  const [database, setDatabase] = useState("ProductsD2RLadder");
  const [productTitle, setProductTitle] = useState("");
  const [collection, setCollection] = useState("amulets");
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [category, setCategory] = useState("unique");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const collOptions = sortedOptions.map((option) => {
      return { id: option.key, value: option.title };
    });
    console.log(collOptions);
    setCollectionOptions(collOptions);
    const selectedCollection = sortedOptions.filter(
      (a) => a.title === collection
    );
    console.log(selectedCollection[0].options);
    const catOptions = selectedCollection[0].options.map((option) => {
      return { id: ~~(Math.random() * 10000), value: option.category };
    });
    console.log(catOptions);
    setCategoryOptions(catOptions);
  }, [collection]);
  const databaseHandler = (e) => {
    setDatabase(e.target.value);
  };
  const collectionHandler = (e) => {
    setCollection(e.target.value);
  };
  const categoryHandler = (e) => {
    setCategory(e.target.value);
  };
  const typeHandler = (e) => {
    setType(e.target.value);
  };
  const titleHandler = (e) => {
    setProductTitle(e.target.value);
  };

  const fetchProduct = async () => {
    const serverAddress = settings.serverAddress;
    if (database && collection && productTitle) {
      setIsLoading(true);
      const response = await fetch(`${serverAddress}/api/admin/products/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          database: database,
          collection: collection,
          category: category,
          type: type,
          title: productTitle,
        }),
      });
      setIsLoading(false);
      const r = await response.text();
    }
  };

  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}
      <Row>
        <Title color={"#a28648"}>Add new Product</Title>
      </Row>
      <Row>
        <Col xs={8}>
          Database:{" "}
          <Select
            onChangeHandler={databaseHandler}
            value={database}
            options={databaseOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          Collection:
          <Select
            onChangeHandler={collectionHandler}
            value={type}
            options={collectionOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          Category:
          <Select
            onChangeHandler={categoryHandler}
            value={type}
            options={categoryOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          Type:
          <SearchInput
            type="text"
            value={type}
            onChangeHandler={typeHandler}
            placeholder={"type"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          Product Title:
          <SearchInput
            type="text"
            value={productTitle}
            onChangeHandler={titleHandler}
            placeholder={"product title"}
          />
        </Col>
      </Row>
      <Button text={"Add new product"} onClick={fetchProduct}></Button>
      <br />
      {/* <Row>
        <Col xs={8}>
          <SearchInput
            type="text"
            value={itemTitle}
            onChangeHandler={titleHandler}
            placeholder={"item title"}
          />
        </Col>
      </Row> */}
    </Container>
  );
};

export default AddProduct;
