import { useState } from "react";
import styles from "../EditProduct.module.css";

import colorOptions from "../colorOptions.json";
import Select from "../../../UI/Select";
import SearchInput from "../../../UI/SearchInput";
import ColorText from "./ColorText";
const PDescription = (props) => {
  const [color, setColor] = useState(props.color);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(props.content);

  const startEditing = () => {
    setIsEditing(true);
  };
  const applyChanges = () => {
    setIsEditing(false);
    props.updateDescription(props.index, content, color);
  };

  const colorHandler = (e) => {
    setColor(e.target.value);
  };

  const contentHandler = (e) => {
    setContent(e.target.value);
  };

  return (
    <tr className={styles.tableRow} key={props.id}>
      <td className={styles.tableCell} style={{ color: props.color }}>
        {isEditing ? (
          <Select
            onChangeHandler={colorHandler}
            value={color}
            options={colorOptions}
            color={color}
          />
        ) : (
          <span onClick={isEditing ? null : startEditing}>
            <ColorText color={color} />
          </span>
        )}
      </td>
      <td className={styles.tableCell} style={{ color: props.color }}>
        {isEditing ? (
          <SearchInput
            type="text"
            value={content}
            onChangeHandler={contentHandler}
            color={color}
          />
        ) : (
          props.content
        )}
      </td>
      <td
        onClick={() => {
          props.removeLineHandler(props.content, props.index);
        }}
      >
        remove
      </td>
      <td onClick={isEditing ? applyChanges : startEditing}>
        {isEditing ? "apply" : "edit"}
      </td>
      <td
        draggable
        onDragStart={(event) => props.onDragStart(event, props.index)}
        onDragOver={props.onDragOver}
        onDrop={(e) => props.onDrop(e, props.index)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="100.000000pt"
          height="100.000000pt"
          viewBox="0 0 100.000000 100.000000"
          preserveAspectRatio="xMidYMid meet"
          style={{ width: "35px", height: "35px", padding: "5px" }}
        >
          <g
            transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path d="M416 921 c-44 -45 -76 -84 -72 -90 10 -15 302 -15 312 0 6 10 -141 169 -156 169 -3 0 -41 -36 -84 -79z m134 -16 l44 -45 -94 0 -94 0 44 45 c24 25 47 45 50 45 3 0 26 -20 50 -45z" />
            <path d="M40 700 c0 -20 7 -20 460 -20 453 0 460 0 460 20 0 20 -7 20 -460 20 -453 0 -460 0 -460 -20z" />
            <path d="M40 500 c0 -20 7 -20 460 -20 453 0 460 0 460 20 0 20 -7 20 -460 20 -453 0 -460 0 -460 -20z" />
            <path d="M40 300 c0 -20 7 -20 460 -20 453 0 460 0 460 20 0 20 -7 20 -460 20 -453 0 -460 0 -460 -20z" />
            <path d="M344 169 c-4 -7 28 -46 75 -93 l81 -81 81 81 c47 47 79 86 75 93 -5 7 -57 11 -156 11 -99 0 -151 -4 -156 -11z m203 -76 l-47 -48 -47 48 -48 47 95 0 95 0 -48 -47z" />
          </g>
        </svg>
      </td>
    </tr>
  );
};

export default PDescription;
