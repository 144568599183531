import { Col, Container, Row } from "react-bootstrap";
import Title from "../../../UI/Title";
import styles from "./Revenue.module.css";
import Button from "../../../UI/Button";
import SingleRevenue from "./SingleRevenue";
import settings from "../../../../settings.json";
import { useState } from "react";
import LoadingSpinner from "../../../Spinner/LoadingSpinner";
const Revenue = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const exportData = async () => {
    if (props.data.length > 0) {
      const serverAddress = settings.serverAddress;
      setIsLoading(false);
      const response = await fetch(
        `${serverAddress}/api/admin/analytics/export`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: props.data,
          }),
        }
      );
      await response.blob().then((blob) => {
        console.log(blob);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "revenue.pdf"; // Specify the desired filename
        a.click();
        URL.revokeObjectURL(url); // Clean up the URL object
      });
      setIsLoading(false);
    }
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}
      <Row>
        <Col>
          <Title>Revenue</Title>
        </Col>
      </Row>
      <Row>
        <Col xs={10}></Col>
        <Col xs={2}>
          <Button onClick={exportData} text={"Export"} />
        </Col>
      </Row>
      <Row>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>Date</td>
                <td className={styles.tableCell}>Orders</td>
                <td className={styles.tableCell}>Gross Sales</td>
                <td className={styles.tableCell}>Returns</td>
                <td className={styles.tableCell}>Coupons</td>
                <td className={styles.tableCell}>Net Sales</td>
                <td className={styles.tableCell}>Taxes</td>
                <td className={styles.tableCell}>Total Sales</td>
              </tr>
              {props.data.map((a) => {
                return <SingleRevenue dayData={a} key={a.day} />;
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Revenue;
