import Select from "../UI/Select";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../UI/Button";
import settings from "../../settings.json";
import SearchInput from "../UI/SearchInput";
import styles from "./Fees.module.css";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Fee from "./Fee";
const Fees = (props) => {
  // const params = useParams();
  // const { collection, category, type } = params;
  const navigate = useNavigate();

  const [fees, setFees] = useState([]);
  const [VATRates, setVATRates] = useState([]);
  const [type, setType] = useState("Order");
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const selectOptions = [
    { id: 1, value: "Order" },
    { id: 2, value: "VAT" },
  ];
  useEffect(() => {
    fetchFees();
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   useEffect(() => {
  //     if (searchId === "") fetchSales();
  //     else {
  //       const filteredSales = sales.filter((sale) => {
  //         return sale.id.toLowerCase().includes(searchId.toLowerCase());
  //       });
  //       setSales(filteredSales);
  //     }
  //   }, [searchId]);

  const fetchFees = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/fees`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        collection: type,
      }),
    });
    const data = await response.json();
    if (type === "Order") setFees(data);
    else if (type === "VAT") {
      const sorted = data.sort(function (a, b) {
        if (a.country < b.country) {
          return -1;
        }
        if (a.country > b.country) {
          return 1;
        }
        return 0;
      });
      setVATRates(sorted);
    }
    setIsLoading(false);
  };

  const removeHandler = async (id) => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    await fetch(`${serverAddress}/api/admin/fees/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    console.log(fees);
    const updatedFees = fees.filter((fee) => fee.id !== id);
    setIsLoading(false);
    setFees(updatedFees);
  };

  const typeHandler = (e) => {
    setType(e.target.value);
  };
  // const searchHandler = (e) => {
  //   setSearchId(e.target.value);
  // };

  const addFee = () => {
    navigate("/fees/add");
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}
      <Row>
        <h1>Fees</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Select
            onChangeHandler={typeHandler}
            value={type}
            options={selectOptions}
          />
        </Col>
        {type === "Order" ? (
          <Col xs={2}>
            <Button onClick={addFee} text={"add fee"} />
          </Col>
        ) : null}
        {/* <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search sales by id"}
          />
        </Col> */}
      </Row>
      <Row>
        <label className={styles.total}>Total: {fees.length}</label>
        <label className={styles.total}>Collection: {type}</label>
        <div className={styles.tableContainer}>
          {type === "Order" ? (
            <table className={styles.table}>
              <tbody>
                <tr className={styles.tableRow}>
                  <td className={styles.tableCell}>ID</td>
                  <td className={styles.tableCell}>Breakpoint USD</td>
                  <td className={styles.tableCell}>Amount USD</td>
                  <td className={styles.tableCell}>Breakpoint EUR</td>
                  <td className={styles.tableCell}>Amount EUR</td>
                  <td className={styles.tableCell}>isEnabled</td>
                  <td className={styles.tableCell}>Date</td>
                  <td className={styles.tableCell}></td>
                </tr>
                {fees.map((fee) => {
                  return (
                    <Fee fee={fee} key={fee.id} removeHandler={removeHandler} />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <table className={styles.table}>
              <tbody>
                <tr className={styles.tableRow}>
                  <td className={styles.tableCell}>ID</td>
                  <td className={styles.tableCell}>Country</td>
                  <td className={styles.tableCell}>VAT Rate</td>
                </tr>
                {VATRates.map((v) => {
                  return (
                    <tr className={styles.tableRow}>
                      <td className={styles.tableCell}>{v.id}</td>
                      <td className={styles.tableCell}>{v.country}</td>
                      <td className={styles.tableCell}>{v.VAT}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default Fees;
