import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
import EditGift from "../components/Gifts/EditGift";
const EditGiftPage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <EditGift />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default EditGiftPage;
