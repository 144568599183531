import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dashboard from "../components/UI/Dashboard";
import HomeDashboard from "../components/HomeDashboard/HomeDashboard";
const HomePage = () => {
  return (
    <Dashboard>
      <Container>
        <Row>
          <Col>
            <HomeDashboard />
          </Col>
        </Row>
      </Container>
    </Dashboard>
  );
};
export default HomePage;
