import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        height: "100vh",
        overflow: "scroll initial",
      }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            GGItems
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink to="/dashboard">
              {/* exact */}
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/products">
              <CDBSidebarMenuItem icon="table">Products</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/users">
              <CDBSidebarMenuItem icon="user">Users</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/content">
              <CDBSidebarMenuItem icon="sticky-note">
                Page Content
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/coupons">
              <CDBSidebarMenuItem icon="credit-card">
                Coupons
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/selector">
              <CDBSidebarMenuItem icon="gamepad">
                Selector Panel
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sections">
              <CDBSidebarMenuItem icon="gamepad">Sections</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sales">
              <CDBSidebarMenuItem icon="shopping-bag">Sales</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/gifts">
              <CDBSidebarMenuItem icon="th-large">
                Gift Selection
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/analytics">
              <CDBSidebarMenuItem icon="chart-line">
                Analytics
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/fees">
              <CDBSidebarMenuItem icon="credit-card">Fees</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/smtp">
              <CDBSidebarMenuItem icon="sticky-note">SMTP</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
            GGItems
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
