import styles from "./TextArea.module.css";
const TextArea = (props) => {
  return (
    <textarea
      className={styles.textarea}
      value={props.value}
      onChange={props.onChangeHandler}
      placeholder={props.placeholder}
    />
  );
};
export default TextArea;
