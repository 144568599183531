import React, { useContext, useState } from "react";
import styles from "./LoginForm.module.css";
import { Col, Container, Row } from "react-bootstrap";
import settings from "../../settings.json";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/auth-context";
import Label from "../UI/Label";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const getUserGeolocationDetails = async () => {
    const response = await fetch(`https://geolocation-db.com/json/`, {
      method: "GET",
    });
    const { IPv4, country_name } = await response.json();

    return { ip: IPv4, country: country_name };
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const serverAddress = settings.serverAddress;
    // const geolocation = await getUserGeolocationDetails();
    const geolocation = { ip: -1, country: -1 };
    console.log("sending");
    const response = await fetch(`${serverAddress}/api/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        pw: password,
        geolocation: geolocation, // geolocation,
      }),
    });
    console.log(response.ok);
    const data = await response.json();
    setIsLoading(false);
    if (data.user.role === "admin") {
      authCtx.setUser(data.user);
      navigate("/dashboard");
    }
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}
      <Row>
        <Col xs={5}></Col>
        <Col>
          <form onSubmit={submitHandler}>
            <div className={styles.container}>
              <Label>email address</Label>
              <SearchInput
                type="email"
                value={email}
                onChangeHandler={emailHandler}
              />
            </div>
            <div className={styles.container}>
              <Label>password</Label>

              <SearchInput
                type="password"
                value={password}
                onChangeHandler={passwordHandler}
              ></SearchInput>
            </div>
            <Button text={"Submit"} type="submit"></Button>
          </form>
        </Col>
        <Col xs={5}></Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
