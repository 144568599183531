import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const isLoggedIn = JSON.parse(localStorage.getItem("user")).role !== "admin";
  if (isLoggedIn) {
    // !== "admin"
    return <Navigate to="/" replace />;
  }
  return children;
};

export default ProtectedRoute;
