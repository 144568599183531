import styles from "./StatisticsContainer.module.css";
const StatisticsContainer = (props) => {
  return (
    <div
      className={styles.container}
      style={{
        border: `2px solid ${props.color}`,
      }}
    >
      {props.children}
    </div>
  );
};

export default StatisticsContainer;
