import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./TopMenu.module.css";
import LoadingSpinner from "../../../Spinner/LoadingSpinner";
import Title from "../../../UI/Title";
import StatisticsContainer from "../../../UI/StatisticsContainer";
const TopMenu = (props) => {
  const [stats, setStats] = useState({
    grossSales: 0,
    netSales: 0,
    taxes: 0,
    returns: 0,
    coupons: 0,
    totalItemsSold: 0,
  });
  useEffect(() => {
    const data = props.data;
    const grossSales = data.reduce((a, b) => a + b.grossSales, 0);
    const netSales = data.reduce((a, b) => a + b.netSales, 0);
    const taxes = data.reduce((a, b) => a + b.tax, 0);
    const returns = data.reduce((a, b) => a + b.returns, 0);
    const coupons = data.reduce((a, b) => a + b.coupons, 0);
    const totalItemsSold = data.reduce((a, b) => a + b.totalItemsSold, 0);
    setStats({
      grossSales,
      netSales,
      taxes,
      returns,
      coupons,
      totalItemsSold,
    });
  }, [props.data]);
  return (
    <Row>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#1303fc"}>
          Gross Sales <br />{" "}
          <span className={styles.price}>{stats.grossSales.toFixed(2)}$</span>
        </StatisticsContainer>
      </Col>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#db0db2"}>
          Net Sales <br />{" "}
          <span className={styles.price}>{stats.netSales.toFixed(2)}$</span>
        </StatisticsContainer>
      </Col>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#e80505"}>
          Taxes <br />{" "}
          <span className={styles.price}>{stats.taxes.toFixed(2)}$</span>
        </StatisticsContainer>
      </Col>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#05c5f5"}>
          Refunds <br />{" "}
          <span className={styles.price}>{stats.returns.toFixed(2)}$</span>
        </StatisticsContainer>
      </Col>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#1fe805"}>
          Coupons <br />{" "}
          <span className={styles.price}>{stats.coupons.toFixed(2)}$</span>
        </StatisticsContainer>
      </Col>
      <Col md={4} style={{ marginTop: "5px" }}>
        <StatisticsContainer color={"#deaa0d"}>
          Total Items Sold <br />{" "}
          <span className={styles.price}>{stats.totalItemsSold}</span>
        </StatisticsContainer>
      </Col>
    </Row>
  );
};

export default TopMenu;
