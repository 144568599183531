import { Link, useNavigate } from "react-router-dom";
import styles from "./Fees.module.css";
import Checkbox from "../UI/Checkbox";
import React, { useState } from "react";
import settings from "../../settings.json";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
const Fee = (props) => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const fee = props.fee;
  const [isEnabled, setIsEnabled] = useState(fee.active);

  const removeHandler = () => {
    props.removeHandler(fee.id);
  };

  return (
    <React.Fragment>
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}

      <tr className={styles.tableRow}>
        <td className={styles.tableCell}>{fee.id}</td>
        <td className={styles.tableCell}>
          {Number(fee.breakpointUSD).toFixed(2)}$
        </td>

        <td className={styles.tableCell}>
          {Number(fee.amountUSD).toFixed(2)}$
        </td>
        <td className={styles.tableCell}>{fee.breakpointEUR.toFixed(2)}€</td>
        <td className={styles.tableCell}>{fee.amountEUR.toFixed(2)}€</td>

        <td className={styles.tableCell}>
          <Checkbox value={isEnabled} />
        </td>
        <td className={styles.tableCell}>{fee.dateCreated}</td>
        <td className={styles.tableCell} onClick={removeHandler}>
          bin
        </td>
      </tr>
    </React.Fragment>
  );
};
export default Fee;
