import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useEffect, useState } from "react";
import Label from "../UI/Label";
import Title from "../UI/Title";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const EditGift = () => {
  const location = useLocation();
  const [gift, setGift] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setGift(location.state);
  }, [location]);

  const [id, setId] = useState(gift.id);
  const [isActive, setIsActive] = useState(gift.active);
  // const [dateCreated, setDateCreated] = useState(gift.dateCreated);

  const [breakpointUSD, setBreakpointUSD] = useState(gift.breakpointUSD);
  const [breakpointEUR, setBreakpointEUR] = useState(gift.breakpointEUR);
  const [discount, setDiscount] = useState(gift.discount);

  const breakpointUSDHandler = (e) => {
    setBreakpointUSD(e.target.value);
  };
  const breakpointEURHandler = (e) => {
    setBreakpointEUR(e.target.value);
  };
  const discountHandler = (e) => {
    setDiscount(e.target.value);
  };

  const editCouponHandler = async () => {
    const updatedCoupon = {
      id: gift.id,
      minimumSpend: breakpointUSD,
      maximumSpend: breakpointEUR,
      active: isActive,
    };
    const serverAddress = settings.serverAddress;
    /*setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/coupons/edit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coupon: updatedCoupon,
      }),
    });
    const data = await response.text();
    setIsLoading(false)
    navigate("/coupons");
    */
  };

  const disableHandler = async () => {
    /*
    const serverAddress = settings.serverAddress;
    setIsLoading(true)
    const response = await fetch(`${serverAddress}/api/admin/coupons/toggle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coupon: gift,
      }),
    });
    const data = await response.text();
    setIsLoading(false)
    navigate("/coupons");
    */
  };

  const removeHandler = async () => {
    /*
    const serverAddress = settings.serverAddress;
    setIsLoading(true)
    const response = await fetch(`${serverAddress}/api/admin/coupons/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coupon: gift,
      }),
    });
    const data = await response.text();
    setIsLoading(false)
    navigate("/coupons");
    */
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}

      <Row>
        <Title>Edit Gift</Title>
      </Row>
      <Row>
        <Col xs={8}>
          <Label>id: {id}</Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>breakpoint USD</Label>
          <SearchInput
            type="number"
            value={breakpointUSD}
            onChangeHandler={breakpointUSDHandler}
            placeholder={"breakpoint usd"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>breakpoint Eur</Label>
          <SearchInput
            type="number"
            value={breakpointEUR}
            onChangeHandler={breakpointEURHandler}
            placeholder={"breakpoint eur"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>discount off</Label>
          <SearchInput
            type="number"
            value={discount}
            onChangeHandler={discountHandler}
            placeholder={"discount off"}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Button onClick={editCouponHandler} text={"Edit Gift"}></Button>
          <Button
            onClick={disableHandler}
            text={gift.active ? "Disable Gift" : "Enable Gift"}
          ></Button>
          <Button onClick={removeHandler} text={"Remove Gift"}></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EditGift;
