import { useEffect, useState } from "react";
import styles from "./Coupons.module.css";
import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import Coupon from "./Coupon";
import LoadingSpinner from "../Spinner/LoadingSpinner";
const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [searchId, setSearchId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchId === "") fetchCoupons();
    else {
      const filteredCoupons = coupons.filter((coupon) => {
        return coupon.code.toLowerCase().includes(searchId.toLowerCase());
      });
      setCoupons(filteredCoupons);
    }
  }, [searchId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchCoupons = async () => {
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    const response = await fetch(`${serverAddress}/api/admin/coupons`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log(data);
    setIsLoading(false);
    setCoupons(data);
  };

  const searchHandler = (e) => {
    setSearchId(e.target.value);
  };
  const addCouponRedirect = () => {
    navigate("./add-new-coupon");
  };
  return (
    <Container>
      {isLoading ? <LoadingSpinner /> : null}
      <Row>
        <h1>Coupons</h1>
      </Row>
      <Row>
        <Col xs={2}>
          <Button onClick={addCouponRedirect} text={"add new coupon"} />
        </Col>
        <Col xs={3}>
          <SearchInput
            type="search"
            value={searchId}
            onChangeHandler={searchHandler}
            placeholder={"search coupons by code"}
          />
        </Col>
      </Row>
      <Row>
        <label className={styles.total}>Total: {coupons.length}</label>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>Code</td>
                <td className={styles.tableCell}>Coupon Type</td>
                <td className={styles.tableCell}>Amount Off</td>
                <td className={styles.tableCell}>Usage / Limit</td>
                <td className={styles.tableCell}>Active</td>
                <td className={styles.tableCell}>Expiry Date</td>
              </tr>
              {coupons.map((coupon) => {
                return <Coupon coupon={coupon} key={coupon.id} />;
              })}
            </tbody>
          </table>
        </div>
      </Row>
    </Container>
  );
};

export default Coupons;
