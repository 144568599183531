import { useEffect, useReducer } from "react";
import AuthContext from "./auth-context";

const defaultAuthState = {
  isLoggedIn: false,
  user: {},
};

const authReducer = (state, action) => {
  if (action.type === "LOGIN") {
    return {
      isLoggedIn: true,
      user: action.user,
    };
  } else if (action.type === "REMOVE") {
    return {
      isLoggedIn: false,
      user: {},
    };
  } else if (action.type === "SET") {
    return {
      isLoggedIn: true,
      user: action.user,
    };
  }
};

const AuthProvider = (props) => {
  const [authState, dispatchAuthAction] = useReducer(
    authReducer,
    defaultAuthState
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) dispatchAuthAction({ type: "SET", user: user });
  }, []);

  const setUserHandler = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatchAuthAction({ type: "LOGIN", user: user });
  };

  const removeUserHandler = () => {
    localStorage.removeItem("user");
    dispatchAuthAction({ type: "REMOVE" });
  };

  const authContext = {
    isLoggedIn: authState.isLoggedIn,
    user: authState.user,
    setUser: setUserHandler,
    removeUser: removeUserHandler,
  };

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
