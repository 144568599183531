import settings from "../../settings.json";
import { Col, Container, Row } from "react-bootstrap";
import SearchInput from "../UI/SearchInput";
import Button from "../UI/Button";
import { useState } from "react";
import Label from "../UI/Label";
import Title from "../UI/Title";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Alert from "../UI/Alert";
import Select from "../UI/Select";
const databaseOptions = [
  { id: 1, value: "ProductsD2RLadder" },
  { id: 2, value: "ProductsD2RNLadder" },
];

const collectionOptions = [
  { id: 1, value: "Amulets" },
  { id: 2, value: "Armors" },
  { id: 3, value: "Belts" },
  { id: 4, value: "Boots" },
  { id: 5, value: "Crafts" },
  { id: 6, value: "Essences" },
  { id: 7, value: "Gear Packages" },
  { id: 8, value: "Gems" },
  { id: 9, value: "Gloves" },
  { id: 10, value: "Grand Charms" },
  { id: 11, value: "Helms" },
  { id: 12, value: "Jewels" },
  { id: 13, value: "Keys" },
  { id: 14, value: "Large Charms" },
  { id: 15, value: "Rares" },
  { id: 16, value: "Rings" },
  { id: 17, value: "Runes" },
  { id: 18, value: "Runeword Armors" },
  { id: 19, value: "Runeword Helms" },
  { id: 20, value: "Runeword Shields" },
  { id: 21, value: "Runeword Weapons" },
  { id: 22, value: "Services" },
  { id: 23, value: "Set Items" },
  { id: 24, value: "Shields" },
  { id: 25, value: "Skill Charms" },
  { id: 26, value: "Small Charms" },
  { id: 27, value: "Unique Charms" },
  { id: 28, value: "Weapons" },
];

const modeOptions = [
  { id: 1, value: "Softcore" },
  { id: 2, value: "Hardcore" },
];
const AddNewGift = () => {
  const [alertVisible, setAlertVisible] = useState({
    visible: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [breakpoint, setBreakpoint] = useState("");
  const [database, setDatabase] = useState("ProductsD2RLadder");
  const [mode, setMode] = useState("Softcore");

  const navigate = useNavigate();

  const discountHandler = (e) => {
    setDiscount(e.target.value);
  };

  const modeHandler = (e) => {
    setMode(e.target.value);
  };
  const databaseHandler = (e) => {
    setDatabase(e.target.value);
  };

  const breakpointHandler = (e) => {
    setBreakpoint(e.target.value);
  };

  const createGiftHandler = async () => {
    if (discount === 0 || breakpoint === "") {
      setAlertVisible({
        visible: true,
        message: "Discount or breakpoint is equal 0",
      });
      setTimeout(() => {
        setAlertVisible({
          visible: false,
          message: "",
        });
      }, 3000);
      return;
    }
    const gift = {
      mode: mode,
      breakpoint: breakpoint,
      discount: discount,
    };
    const serverAddress = settings.serverAddress;
    setIsLoading(true);
    console.log(gift);
    await fetch(`${serverAddress}/api/admin/gifts/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gift: gift,
      }),
    });
    setIsLoading(false);
    // navigate("/gifts");
  };
  return (
    <Container style={{ textAlign: "left" }}>
      {isLoading ? <LoadingSpinner /> : null}
      {alertVisible.visible ? <Alert message={alertVisible.message} /> : null}

      <Row>
        <Title>Create New Gift</Title>
      </Row>
      <Row>
        <Col></Col>
      </Row>
      <Row>
        <Col xs={9}>
          <Label>Breakpoint (USD)</Label>
          <SearchInput
            type="number"
            value={breakpoint}
            onChangeHandler={breakpointHandler}
            placeholder={"breakpoint to spend"}
          />
        </Col>
      </Row>
      <Row>
        <Label>Game Mode</Label>
        <Col xs={9}>
          <Select
            onChangeHandler={modeHandler}
            value={mode}
            options={modeOptions}
          />
        </Col>
        <Label>Realm</Label>
        <Col xs={9}>
          <Select
            onChangeHandler={databaseHandler}
            value={database}
            options={databaseOptions}
          />
        </Col>
        <Label>Percentages off</Label>
        <Col xs={9}>
          <SearchInput
            type="number"
            value={discount}
            onChangeHandler={discountHandler}
            placeholder={""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Title></Title>
          <Button onClick={createGiftHandler} text={"Create Gift"}></Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewGift;
