import React, { useEffect, useState } from "react";
import Options from "../Products/Options.json";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../UI/Checkbox";

const Collection = (props) => {
  const [isActive, setIsActive] = useState(props.includeAll);

  useEffect(() => {
    setIsActive(props.includeAll);
  }, [props.includeAll]);

  const clickHandler = (e) => {
    setIsActive(e.target.checked);
    props.select(props.title, e.target.checked);
  };
  return (
    <Col md={6}>
      <Row>
        <Col xs={2}>
          <Checkbox value={isActive} onChangeHandler={clickHandler} />
        </Col>
        <Col>{props.title}</Col>
      </Row>
    </Col>
  );
};

export default Collection;
